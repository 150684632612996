const initialState = [
    // {
    //     productId: 1000,
    //     quantity: 5
    // }
];

const CheckOtuReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CHECKOUT_ADD_PRODUCT': {
            return action.payload;
        }

        default:
            return state;
    }
};

export default CheckOtuReducer;
