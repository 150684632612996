/* eslint-disable prettier/prettier */
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useState } from 'react';
import { Badge, Button, Card, Form, Modal, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import DetectDevice from '../../../utils/DetectDevice';


function addressInitSet(payload) {
    return {
        type: 'ADDRESS_INIT_SET',
        payload,
    };
}
const setAddressData = () =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_USER_ADDRESS)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
const creatingAddress = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_USER_ADDRESS_CREATE, params)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function AddressTable(props) {
    const { data,handleChange,selectedValue } = props;

    return (
        <tr style={{ fontSize: '12px' }}>
            <td>1</td>
            <td>
                {data.name} <br />
                
                <Badge variant="warning">
                    {data.is_home === 1 ? 'Home' : 'Office'}
                </Badge>
                                            
            </td>
            <td>
                {data.phone}
                <br />
                {data.email}
            </td>
            <td>
                {data.address},
                <br />
                {data.area},
                <br />
                {data.city},{data.district}
            </td>
            <td>
            {data.area},
            </td>

            <td>
            <Radio
                checked={selectedValue.toString() === data.id.toString()}
                onChange={handleChange}
                value={data.id}
                name="radio-button-demo"
                
            />
            </td>
        </tr>
    );
}
function AddressCard(props){
    const { data,handleChange,selectedValue } = props;
    
    return (
        <div className="form-row">
            <div className="col-4">
                {data.name} <br />
                <Badge variant="warning">
                    {data.is_home === 1 ? 'Home' : 'Office'}
                </Badge>
            </div>
            <div className="col-7">
                {data.phone}
                <br />
                {data.email}
                <br />
                {data.address},
                <br />
                {data.area},
                <br />
                {data.city},{data.district}
            </div>
            
            <div className="col-1">
            <Radio
                checked={selectedValue.toString() === data.id.toString()}
                onChange={handleChange}
                value={data.id}
                name="radio-button-demo"
                
            />
            </div>
        </div>
    )
}
function UserAddressModal(props) {
    const { state, setAddModal,  setSelectedValue} = props;
    const [name,setName]=useState('');
    const [phone,setPhone]=useState('');
    const [email, setEmail] = useState('');
    const [district,setDistrict]=useState('');
    const [city,setCity]=useState('');
    const [area,setArea]=useState('');
    const [address,setAddress]=useState('');
    const dispatch = useDispatch();
    const [selectedType, setSelectedType] = useState(1);

    const handleChangeType = (event) => {
        setSelectedType(event.target.value);
    };
    const handleValueChange=(event)=>{
       if (event.target.name==='name') {
        setName(event.target.value);
       }
       if (event.target.name==='phone') {
        setPhone(event.target.value);
       }
       if (event.target.name==='email') {
        setEmail(event.target.value);
       }
       if (event.target.name==='district') {
        setDistrict(event.target.value);
       }
       if (event.target.name==='city') {
        setCity(event.target.value);
       }
       if (event.target.name==='area') {
        setArea(event.target.value);
       }
       if (event.target.name==='address') {
        setAddress(event.target.value);
       }
    }
    const handleSubmit=()=>{
        
        creatingAddress({ name,phone,email,district,city,area,address,selectedType})
            .then((response) => {
                // DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    
                    setAddressData()
                        .then((responseAddress) => {
                            dispatch(addressInitSet(responseAddress.data));
                            setAddModal(false);
                            setSelectedValue(response.data.createdId)
                            
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    return (
        <>
            <Modal
                size="lg"
                show={state}
                onHide={() => setAddModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Add New Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="row">
                        <div className="col-12 col-lg-6">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Full Name</Form.Label>
                                <input className="form-control" name="name" value={name} onChange={handleValueChange} type="text" placeholder="Enter Full Name" />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Phone Number</Form.Label>
                                <input className="form-control" name="phone" value={phone} onChange={handleValueChange} type="text" placeholder="Enter Phone Number" />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <input className="form-control" name="email" value={email} onChange={handleValueChange} type="text" placeholder="Enter Email" />
                            </Form.Group>
                        </div>
                        <div className="col-12 col-lg-6">
                        <Form.Group controlId="formBasicEmail">
                                <Form.Label>Address</Form.Label>
                                <input className="form-control" name="address" value={address} onChange={handleValueChange} type="text" placeholder="Enter Address" />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>District</Form.Label>
                                <input className="form-control" name="district" value={district} onChange={handleValueChange} type="text" placeholder="Enter district" />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>City</Form.Label>
                                <input className="form-control" name="city" value={city} onChange={handleValueChange} type="text" placeholder="Enter City" />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Area</Form.Label>
                                <input className="form-control" name="area" value={area} onChange={handleValueChange} type="text" placeholder="Enter Area" />
                            </Form.Group>
                           
                            <RadioGroup aria-label="address type" name="address_type" value={selectedType} onChange={handleChangeType}>
                                <FormControlLabel value="1" control={<Radio />} label="Home" />
                                <FormControlLabel value="2" control={<Radio />} label="Office" />
                            </RadioGroup>
                            
                            <Button style={{ float: 'right' }} variant="primary" onClick={handleSubmit}>
                                Save
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
function UserAddressListModal(props) {
    const { state ,pSetAddModal,setSelectedAddress,userAddresses} = props;
    const [addModal, setAddModal] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState(userAddresses[0].id);

    const handleChange = (event) => {
      setSelectedValue(event.target.value);
      setAddModal(false)
      pSetAddModal(false)
    };
    setSelectedAddress(selectedValue);
    return (
        <>
            <Modal
                size="lg"
                show={state}
                onHide={() => pSetAddModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Address{' '}
                        
                        <Chip label="Add New Address" color="primary" variant="outlined" onClick={() => setAddModal(true)}  />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <DetectDevice>
                    <Table striped bordered hover type="lg">
                        <thead>
                            <tr style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                <th>#</th>
                                <th>Full Name</th>
                                <th>Contact</th>
                                <th>Address</th>
                                <th>Area</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {userAddresses.map((item) => (
                                <AddressTable data={item} setAddModal={pSetAddModal} key={item.id} selectedValue={selectedValue} handleChange={handleChange} />
                            ))}
                        </tbody>
                    </Table>
                    <div  type="sm|xs"> 
                    
                        {userAddresses.map((item) => (
                            <Card style={{marginTop:'5px'}}>
                                <Card.Body>
                                    <AddressCard  data={item} setAddModal={pSetAddModal} key={item.id} selectedValue={selectedValue} handleChange={handleChange}/>
                                </Card.Body>
                            </Card>
                            ))}
                        
                    </div>
                    
                </DetectDevice>
                </Modal.Body>
            </Modal>
            <UserAddressModal state={addModal} setAddModal={setAddModal} setSelectedValue={setSelectedValue}/>
        </>
    );
}
export default UserAddressModal;
export { UserAddressListModal };


