import React from 'react';

function CartProductVariant({ data }) {
    if (data) {
        return Object.keys(data).map((key) => <li key={key}>{data[key]}</li>);
    }

    return null;
}
export default CartProductVariant;
