/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import AddMemberIcon from '../../../../assets/images/Add_Member.png';
import HomeIcon from '../../../../assets/images/HomeAddress.png';
import OfficeIcon from '../../../../assets/images/OfficeAddress.png';
import UserPImage from '../../../../assets/images/user-image.png';
import ConfigApi from '../../../../configs/ConfigApi';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import TimeStampToDate from '../../../utils/TimeStampToDate';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import UserMenu from '../comp/UserMenu';
import UserUpdateModals from '../modals/UserUpdateModals';
import FamilyAddModals from '../section/FamilyAddModals';

const getOrdersData = () =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_USER_ORDERS_RECENT)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const getFamilyData = () =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_USER_FAMILY)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function Address(props) {
    const { data } = props;
    console.log(data);
    return (
        <>
            {data.length !== 0
                ? data.map((item) => (
                      <tr style={{ fontSize: '12px' }} key={item.id}>
                          <td>
                              {item.name} <br />{' '}
                          </td>
                          <td className="user-address-td-detail">
                              <span className="mr-1">
                                  {item.is_home === 1 ? (
                                      <img src={HomeIcon} alt="Home" />
                                  ) : (
                                      <img src={OfficeIcon} alt="Home" />
                                  )}
                              </span>
                              <span>
                                  {item.address},{item.area},
                                  <br />
                                  {item.city},{item.district}
                              </span>
                          </td>
                          <td>
                              {item.phone}
                              <br />
                              {item.email}
                          </td>
                          <td>{item.phone}</td>
                          <td>
                              <Link
                                  to={`/user/address/${item.id}/update/`}
                                  className="user-address-edit-btn"
                              >
                                  Edit
                              </Link>
                          </td>
                      </tr>
                  ))
                : null}
        </>
    );
}
function AddressInfo({ userAddresses, activeBtnA }) {
    return (
        <div className="user-profile-address" style={{ display: activeBtnA ? 'block' : 'none' }}>
            <Table borderless responsive="sm" style={{ marginBottom: 0 }}>
                <thead className="user-profile-address-thead">
                    <tr
                        style={{
                            fontSize: '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        <th>Full Name</th>
                        <th>Address</th>
                        <th>Contact</th>
                        <th colSpan="2" />
                    </tr>
                </thead>
                <tbody className="user-profile-address-tbody">
                    <Address data={userAddresses} />
                </tbody>
            </Table>
            <Link
                to="/user/address/create/"
                style={{ margin: 'auto', float: 'right' }}
                type="button"
            >
                <img src={AddMemberIcon} alt="Add Member" />
            </Link>
        </div>
    );
}
function ProfileInfo({ userInfo, activeBtnP }) {
    return (
        <div style={{ display: activeBtnP ? 'block' : 'none' }}>
            <div className="user-profile-show">
                <div className="row">
                    <div className="col-8">
                        <div className="user-profile-show-title">Email</div>
                        <div className="user-profile-show-data">
                            {userInfo.default_email ? userInfo.default_email : 'Not Set'}
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="user-profile-show-btn">
                            <Link to="/user/profile/update/email/">Change</Link>
                        </div>
                    </div>
                </div>
                <div className="user-profile-show-devider" />
            </div>
            <div className="user-profile-show">
                <div className="row">
                    <div className="col-8">
                        <div className="user-profile-show-title">Name</div>
                        <div className="user-profile-show-data">
                            {userInfo.name ? userInfo.name : 'Not Set'}
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="user-profile-show-btn">
                            <Link to="/user/profile/update/name/">Change</Link>
                        </div>
                    </div>
                </div>
                <div className="user-profile-show-devider" />
            </div>
            <div className="user-profile-show">
                <div className="row">
                    <div className="col-8">
                        <div className="user-profile-show-title">Phone Number</div>
                        <div className="user-profile-show-data">
                            {userInfo.default_contact ? userInfo.default_contact : 'Not Set'}
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="user-profile-show-btn">
                            <Link to="/user/profile/update/contact/">Change</Link>
                        </div>
                    </div>
                </div>
                <div className="user-profile-show-devider" />
            </div>
        </div>
    );
}
function FamilyInfo({ userFamily }) {
    return (
        <>
            {userFamily.length !== 0 ? (
                <>
                    {userFamily.length !== 0 ? (
                        userFamily.map((item) => (
                            <div className="col-6 mb-4" key={item.sl}>
                                <div className="user-profile-family-member">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="user-profile-family-member-img">
                                                <img src={UserPImage} alt="User" />
                                            </div>
                                            <div className="user-profile-family-member-type">
                                                Father
                                            </div>
                                        </div>
                                        <div className="col-4 pl-0">
                                            <div className="user-profile-family-member-detail">
                                                Name
                                            </div>
                                            <div className="user-profile-family-member-value">
                                                {item.name}
                                            </div>
                                            <div className="user-profile-family-member-detail mt-4">
                                                Contact
                                            </div>
                                            <div className="user-profile-family-member-value">
                                                {item.phone}
                                            </div>
                                        </div>
                                        <div className="col-4 pl-0">
                                            <div className="user-profile-family-member-detail">
                                                Date of Birth
                                            </div>
                                            <div className="user-profile-family-member-detail">
                                                <TimeStampToDate timeStamp={item.birth_date} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <Link
                            to="/user/profile/add/family/"
                            className="btn btn-xs btn-outline-primary"
                            style={{ margin: 'auto' }}
                            type="button"
                        >
                            <img src={AddMemberIcon} alt="Add Member" />
                        </Link>
                    )}
                </>
            ) : null}
        </>
    );
}
function UserProfilePage() {
    PageSetProperties('User');

    const dispatch = useDispatch();
    const { show, shopData } = InitInfo();
    const setShow = useState()[1];
    const isLogin = localStorage.getItem('Secure-Access');
    const history = useHistory();
    const userInfo = useSelector((state) => state.userInformation);
    const userAddresses = useSelector((state) => state.userAddresses);
    const [userFamily, setUserFamily] = useState([]);
    const [activeBtnP, setactiveBtnP] = useState('user-profile-btn-active');
    const [activeBtnA, setactiveBtnA] = useState('');
    const [activeBtnF, setactiveBtnF] = useState('');

    const handleButton = (e) => {
        console.log(e);
        if (e === 'profile') {
            setactiveBtnP('user-profile-btn-active');
            setactiveBtnA('');
            setactiveBtnF('');
        } else if (e === 'family') {
            setactiveBtnF('user-profile-btn-active');
            setactiveBtnP('');
            setactiveBtnA('');
        } else if (e === 'address') {
            setactiveBtnA('user-profile-btn-active');
            setactiveBtnP('');
            setactiveBtnF('');
        }
    };
    useEffect(() => {
        // Collect API Data
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                if (isLogin) {
                    getOrdersData()
                        .then((response) => {
                            setShow(initStatus);
                            console.log(response);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    getFamilyData()
                        .then((response) => {
                            setUserFamily(response.data);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    history.push('/registration?profile');
                }
                // Collect Recent Orders
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, history, isLogin, setShow]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu shopData={shopData} />

            <section className="user-section">
                <Container>
                    <div className="row">
                        <UserMenu />

                        <div className="col-12 col-lg-9">
                            <div className="user-profile">
                                <div className="user-profile-title">
                                    <div className="user-profile-title-view">
                                        <button
                                            className={activeBtnP}
                                            onClick={() => handleButton('profile')}
                                            type="button"
                                        >
                                            My Profile
                                        </button>
                                        <button
                                            className={activeBtnF}
                                            type="button"
                                            onClick={() => handleButton('family')}
                                        >
                                            Family Member
                                        </button>
                                        <button
                                            className={activeBtnA}
                                            type="button"
                                            onClick={() => handleButton('address')}
                                        >
                                            Adress Book
                                        </button>
                                    </div>
                                </div>
                                <AddressInfo
                                    activeBtnA={activeBtnA}
                                    userAddresses={userAddresses || []}
                                />

                                <div
                                    className="user-profile-family"
                                    style={{ display: activeBtnF ? 'block' : 'none' }}
                                >
                                    <div className="row">
                                        <FamilyInfo userFamily={userFamily || []} />
                                    </div>
                                    <Link
                                        to="/user/profile/add/family/"
                                        style={{ margin: 'auto', float: 'right' }}
                                        type="button"
                                    >
                                        <img src={AddMemberIcon} alt="Add Member" />
                                    </Link>
                                </div>
                                <ProfileInfo userInfo={userInfo} activeBtnP={activeBtnP} />
                            </div>
                        </div>
                        <UserUpdateModals userInfo={userInfo} />
                        <FamilyAddModals />
                    </div>
                </Container>
            </section>

            <Footer />
        </Preview>
    );
}
export default UserProfilePage;
