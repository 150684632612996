import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import ExclusiveProductSection from '../../products/section/ExclusiveProductSection';
import FeatureProductSection from '../../products/section/FeatureProductSection';
import FlashSaleProductSection from '../../products/section/FlashSaleProductSection';
import NewProductSection from '../../products/section/NewProductSection';
import ProductSection from '../../products/section/ProductSection';
import TopSellProductSection from '../../products/section/TopSellProductSection';
import UserLoginComps from '../comp/UserLoginComps';
// General Parameter of Each Page
// 1. Title Set
// 2. Data Load (From DB) In a single File
// 3. Spinner until load Data
import Footer from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';
import SliderSection from '../section/SliderSection';

export default function IndexPage() {
    PageSetProperties();
    const dispatch = useDispatch();
    const { shopData, initData, products, campaignList, featureCategoryOptions } = InitInfo();
    const [show, setShow] = useState(!!initData.initId);

    useEffect(() => {
        // effect

        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <UserLoginComps />

            <HeaderSection shopData={shopData} />
            <SliderSection />
            {campaignList &&
                campaignList.map((list) => (
                    <FlashSaleProductSection list={list} productData={products} key={list.id} />
                ))}
            <FeatureProductSection productData={products} featureProduct={featureCategoryOptions} />
            <ProductSection productData={products} />
            <ExclusiveProductSection productData={products} />
            <NewProductSection productData={products} />
            <TopSellProductSection />

            <Footer />
        </Preview>
    );
}
