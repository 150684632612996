import Chip from '@material-ui/core/Chip';
import React, { useEffect, useState } from 'react';
import { Badge, Card, Container, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import UserMenu from '../comp/UserMenu';
import AddressAddModal from '../section/AddressAddModal';
import AddressUpdateModal from '../section/AddressUpdateModal';

function Address(props) {
    const { data } = props;

    return (
        <tr style={{ fontSize: '12px' }}>
            <td>1</td>
            <td>
                {data.name} <br />
                <Badge className="badge badge-warning">
                    {' '}
                    {data.is_home === 1 ? 'Home' : 'Office'}
                </Badge>
            </td>
            <td>
                {data.phone}
                <br />
                {data.email}
            </td>
            <td>
                {data.address},
                <br />
                {data.area},
                <br />
                {data.city},{data.district}
            </td>

            <td>
                <Link to={`/user/address/${data.id}/update/`} className="d-block w-100">
                    Edit
                </Link>
                {/* <Badge variant="success" onClick={handleEdit}>
                    Edit
                </Badge> */}

                {data.id ? <AddressUpdateModal addressId={data.id} selectedAddress={data} /> : null}
            </td>
        </tr>
    );
}
function UserAddressPage() {
    const address = useSelector((state) => state.userAddresses);
    PageSetProperties('User');

    const dispatch = useDispatch();
    const history = useHistory();

    const { shopData, show } = InitInfo();
    const isLogin = localStorage.getItem('Secure-Access');
    // const { initData, userInformation } = store.getState();
    const setShow = useState()[1];

    useEffect(() => {
        // Collect API Data
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                if (isLogin) {
                    setShow(initStatus);
                } else {
                    history.push('/registration?address');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, history, isLogin, setShow]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu shopData={shopData} />

            <section className="user-section">
                <Container>
                    <div className="row">
                        <UserMenu />
                        <div className="col-12 col-lg-9">
                            <div className="user-title">
                                <div className="user-title-view">
                                    <h6>Address Book</h6>
                                </div>
                            </div>
                            <Card className="user-card">
                                <Card.Body>
                                    <Table borderless responsive="sm">
                                        <thead>
                                            <tr style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                                <th>#</th>
                                                <th>Full Name</th>
                                                <th>Contact</th>
                                                <th>Address</th>
                                                <th />
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {address.map((item) => (
                                                <Address data={item} key={item.id} />
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Link to="/user/address/create/" className="d-block w-100">
                                        <Chip
                                            label="Add New Address"
                                            color="primary"
                                            variant="outlined"
                                        />
                                    </Link>
                                </Card.Body>
                            </Card>
                        </div>
                        <AddressAddModal exiturl="/user/address/" />
                    </div>
                </Container>
            </section>
            <Footer />
        </Preview>
    );
}
export default UserAddressPage;
