import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ProdutctVariantModal from '../../app/products/modals/ProdutctVariantModal';

function BuyNowButton({ myClassName, icon, title, size, productData }) {
    const [modalshow, setModalShow] = useState(false);

    const handleModalClose = () => setModalShow(false);

    const handleModalShow = () => {
        setModalShow(true);
    };
    return (
        <>
            <Button
                variant="outline-info"
                className={[myClassName || 'ThemeBtn'].join(' ')}
                size={size || 'sm'}
                onClick={handleModalShow}
            >
                {icon && <FontAwesomeIcon icon={icon} />}
                {icon ? ' ' : ''} {title || 'Button'}
            </Button>

            {modalshow && (
                <ProdutctVariantModal
                    modalshow={modalshow}
                    handleModalClose={handleModalClose}
                    productData={productData}
                />
            )}
        </>
    );
}

export default BuyNowButton;
