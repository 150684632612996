/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-await-in-loop */

import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import SnackbarOpenDispatch from '../../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import { UserModalHeader } from '../../users/modals/comp/UserModalHeaderFooter';

const creatingSupport = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_SUPPORT_CREATE, params)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};
export default function SupportAddModal(props) {
    const { prev, next, step, exiturl, supportDepartment } = props;
    const { shopId } = InitInfo();
    const history = useHistory();
    const dispatch = useDispatch();

    const [subject, setSubject] = useState('');
    const [sDepartemnt, setSDepartemnt] = useState('10000001');
    const [ticket, setTicket] = useState('');
    const [relatedTopic, setRelatedTopic] = useState('delivery');
    const [attachment, setAttachment] = useState('');
    const [isUrgent, setIsUrgent] = useState(0);

    const handleChangeValue = (event) => {
        if (event.target.name === 'subject') {
            setSubject(event.target.value);
        }
        if (event.target.name === 'sDepartment') {
            setSDepartemnt(event.target.value);
        }
        if (event.target.name === 'ticket') {
            setTicket(event.target.value);
        }
        if (event.target.name === 'relatedTopic') {
            setRelatedTopic(event.target.value);
        }
        if (event.target.name === 'attachment') {
            setAttachment(event.target.value);
        }
        if (event.target.name === 'isUrgent') {
            setIsUrgent(event.target.value === 'on' ? 1 : 0);
        }
    };

    const handleNextClick = () => {
        creatingSupport({
            subject,
            sDepartemnt,
            ticket,
            relatedTopic,
            attachment,
            isUrgent,
            shopId,
        })
            .then((response) => {
                // DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    handelSnackbarOpen(dispatch, response.data.message);

                    history.push(exiturl);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('image', files[x]);
            await AxiosAuth.post(ConfigApi.API_IMAGE_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    setAttachment(response.data.imageUrl);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <>
            <Modal size="lg" {...props} aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Body>
                    <UserModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title="Add New Ticket"
                    />
                    <Form className="row">
                        <div className="col-12">
                            <small>
                                For technical related tickets,please make sure to include all the
                                details which might be useful for our support team in handling your
                                ticket.{' '}
                            </small>
                        </div>

                        <div className="col-12 col-lg-6">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Subject</Form.Label>
                                <input
                                    className="form-control"
                                    name="subject"
                                    type="text"
                                    placeholder="Enter Subject"
                                    value={subject}
                                    onChange={handleChangeValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Group controlId="formBasicEmail2">
                                <Form.Label>Department</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="sDepartment"
                                    value={sDepartemnt}
                                    onChange={handleChangeValue}
                                >
                                    {supportDepartment &&
                                        supportDepartment.map((support) => (
                                            <option value={support.id} key={support.id}>
                                                {support.department}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="col-12">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Ticket</Form.Label>
                                <textarea
                                    className="form-control"
                                    name="ticket"
                                    type="text"
                                    placeholder="Enter Ticket Detail"
                                    value={ticket}
                                    onChange={handleChangeValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Group controlId="formBasicEmail10">
                                <Form.Label>Related Topics</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="relatedTopic"
                                    value={relatedTopic}
                                    onChange={handleChangeValue}
                                >
                                    <option value="delivery" key={1}>
                                        Delivery Issue
                                    </option>
                                    <option value="paymnet" key={2}>
                                        Payment Issue
                                    </option>
                                    <option value="refund" key={3}>
                                        Refund Issue
                                    </option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Attachment</Form.Label>
                                <input
                                    type="file"
                                    name="file"
                                    onChange={handleOnFileChange}
                                    multiple
                                    accept="image/png, image/jpeg,application/pdf"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Check
                                    onChange={handleChangeValue}
                                    name="isUrgent"
                                    type="checkbox"
                                    label="Urgent Response Required !"
                                />
                            </Form.Group>
                        </div>
                    </Form>
                    <div className="app-style-footer">
                        <Button
                            className="modal-footer-btn-fill"
                            onClick={(event) => {
                                handleNextClick(event, next);
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
