import ConfigApi from '../../configs/ConfigApi';
import SnackbarOpenDispatch from '../dispatches/SnackbarOpenDispatch';
import AxiosAuth from './AxiosAuth';

const UtilAuth = {};

UtilAuth.isLoggedIn = (response) => {
    if (response.headers['secure-auth']) {
        return true;
    }

    return false;
};

UtilAuth.redirectIsFailed = (response, history, to = '/login/') => {
    if (history && !response.headers['secure-auth']) {
        history.push(to);
    }
};

UtilAuth.phoneLogin = (countryCode, phoneNo, shopId, dispatch) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_LOGIN_PHONE, {
            phone_no: phoneNo,
            country_code: countryCode,
            shop_id: shopId,
        })
            .then((response) => {
                // resolve(response);

                if (response.data.error !== 0) {
                    // Toast
                    SnackbarOpenDispatch(dispatch, response.data.message);
                }
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
UtilAuth.phoneLoginOtpSend = (countryCode, phoneNo, shopId, dispatch) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_LOGIN_PHONE_OTP, {
            country_code: countryCode,
            phone_no: phoneNo,
            shop_id: shopId,
        })
            .then((response) => {
                // resolve(response);

                if (response.data.error !== 0) {
                    // Toast
                    SnackbarOpenDispatch(dispatch, response.data.message);
                }
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

UtilAuth.phoneLoginByOtp = (countryCode, phoneNo, shopId, otp, dispatch) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_LOGIN_PHONE_BY_OTP, {
            phone_no: phoneNo,
            country_code: countryCode,
            shop_id: shopId,
            otp,
        })
            .then((response) => {
                // resolve(response);

                if (response.data.error !== 0) {
                    // Toast
                    SnackbarOpenDispatch(dispatch, response.data.message);
                }
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

UtilAuth.phoneLoginByPass = (countryCode, phoneNo, shopId, password, dispatch) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_LOGIN_PHONE_BY_PASS, {
            phone_no: phoneNo,
            country_code: countryCode,
            shop_id: shopId,
            password,
        })
            .then((response) => {
                // resolve(response);

                if (response.data.error !== 0) {
                    // Toast
                    SnackbarOpenDispatch(dispatch, response.data.message);
                }
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

export default UtilAuth;
