import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ConfigApi from '../../../../configs/ConfigApi';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import UserMenu from '../../users/comp/UserMenu';
import SupportModal from '../section/SupportModal';
import SupportSection from '../section/SupportSection';

const getSupportData = () =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_SUPPORT)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
export default function SupportPage() {
    PageSetProperties('Support');
    const dispatch = useDispatch();
    const { shopData, initData } = InitInfo();
    const [supportDepartment, setSupportDepartment] = useState([]);
    const [supportData, setSupportData] = useState([]);

    const [show, setShow] = useState(!!initData.initId);

    useEffect(() => {
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
                getSupportData()
                    .then((response) => {
                        setSupportDepartment(response.data.supportDepartment);
                        setSupportData(response.data.supportAr);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu shopData={shopData} />
            <section className="user-section">
                <Container>
                    <div className="row">
                        <UserMenu />

                        <div className="col-12 col-lg-9">
                            <SupportSection
                                supportData={supportData}
                                supportDepartment={supportDepartment}
                            />
                        </div>
                    </div>
                </Container>
            </section>

            <Footer />
            <SupportModal supportDepartment={supportDepartment} />
        </Preview>
    );
}
