import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import InvoiceSection from '../section/InvoiceSection';

const getInvoiceData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_INVOICE, {
            params,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
export default function ProductInvoicePage() {
    PageSetProperties('Checkout');
    const { shopData, initData, products } = InitInfo();
    const dispatch = useDispatch();
    const [show, setShow] = useState(!!initData.initId);
    const [invoiceItemData, setInvoiceItemData] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);

    const [allInvoice, setAllInvoice] = useState([]);
    const history = useHistory();
    const { invoiceId } = useParams();
    const isLogin = localStorage.getItem('Secure-Access');
    const [addressAr, setAddressAr] = useState([]);

    React.useEffect(() => {
        // Collect API Data
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                if (isLogin) {
                    setShow(initStatus);
                } else {
                    history.push('/registration');
                }
            })
            .catch((err) => {
                console.log(err);
            });
        // Collect invoice data
        getInvoiceData({ invoiceId })
            .then((response) => {
                setInvoiceData(response.data.invoice);
                setInvoiceItemData(response.data.invoiceItem);
                setAllInvoice(response.data.allInvoice);
                setAddressAr(response.data.addressAr);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, history, invoiceId, isLogin]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu shopData={shopData} />
            <InvoiceSection
                productData={products}
                invoiceData={invoiceData}
                invoiceItemData={invoiceItemData}
                allInvoice={allInvoice}
                address={addressAr}
            />
            <Footer />
        </Preview>
    );
}
