import Chip from '@material-ui/core/Chip';
import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DetectDevice from '../../../utils/DetectDevice';
import FindInArray from '../../../utils/FindInArray';

function SupportListDesktopView({ supportData, supportDepartment }) {
    const FindArr = new FindInArray();
    FindArr.init(supportDepartment, 'id');
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Ticket ID</th>
                    <th>Ticket</th>
                    <th>Subject</th>
                    <th>Department</th>
                    <th>Status</th>
                    <th>Time</th>
                </tr>
            </thead>
            <tbody>
                {supportData &&
                    supportData.map((support) => {
                        const department = FindArr.find(support.department_sl);

                        return (
                            <tr key={support.id}>
                                <td>
                                    <Link to={`/support/${support.id}/detail`}>{support.id} </Link>
                                </td>
                                <td>{support.ticket}</td>
                                <td>{support.subject}</td>
                                <td>{department.department}</td>
                                <td>{support.status}</td>
                                <td>{support.time_created}</td>
                            </tr>
                        );
                    })}
            </tbody>
        </Table>
    );
}
function SupportListMobileView({ supportData, supportDepartment }) {
    const FindArr = new FindInArray();
    FindArr.init(supportDepartment, 'id');
    return (
        <div style={{ padding: 10, border: '1px solid #0000003b' }}>
            {supportData &&
                supportData.map((support) => {
                    const department = FindArr.find(support.department_sl);
                    return (
                        <div className="row" key={support.id}>
                            <div className=" col-12">{support.ticket}</div>
                            <div className=" col-6">{support.subject}</div>
                            <div className=" col-6">{department.department}</div>
                            <div className=" col-6">{support.status}</div>
                            <div className=" col-6">{support.time_created}</div>
                        </div>
                    );
                })}
        </div>
    );
}
export default function SupportSection({ supportData, supportDepartment }) {
    return (
        <div className="form-row">
            <div className="col-12 mb-4">
                <Card className="cart-section-card">
                    <Card.Body>
                        <div className="cart-section-product">
                            <div className="cart-section-product-top">
                                <h6>
                                    SUPPORT TICKETS{' '}
                                    <Link to="/support/add/ticket/">
                                        <Chip
                                            label="Add Tickets"
                                            color="primary"
                                            variant="outlined"
                                        />
                                    </Link>
                                </h6>
                            </div>
                        </div>
                        <DetectDevice>
                            <div type="lg">
                                <SupportListDesktopView
                                    supportData={supportData}
                                    supportDepartment={supportDepartment}
                                />
                            </div>

                            <div className="row" type="xs|sm">
                                <SupportListMobileView
                                    supportData={supportData}
                                    supportDepartment={supportDepartment}
                                />
                            </div>
                        </DetectDevice>
                        <div />
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}
