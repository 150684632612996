/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AttachmentIcon from '@material-ui/icons/Attachment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SendIcon from '@material-ui/icons/Send';
import React, { createRef } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
// import '../../products/comp/effects/effectOne.scss';

function MessageShow({ ticketMsg, userInfo }) {
    return (
        <>
            {ticketMsg.user_sl !== userInfo.id ? (
                <div className="incoming_msg">
                    <div className="incoming_msg_img">
                        <div className="profile-user-circle-chat">
                            <span>KB</span>
                        </div>
                    </div>
                    <div className="received_msg">
                        <div className="received_withd_msg">
                            {ticketMsg.file_url ? (
                                <img src={ticketMsg.file_url} alt={ticketMsg.subject} />
                            ) : (
                                <p>{ticketMsg.ticket_detail}</p>
                            )}

                            <span className="time_date">
                                {' '}
                                {ticketMsg.timeDate} | {ticketMsg.dayDate}
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="outgoing_msg">
                    <div className="sent_msg">
                        {ticketMsg.file_url ? (
                            <img src={ticketMsg.file_url} alt={ticketMsg.subject} />
                        ) : (
                            <p>{ticketMsg.ticket_detail}</p>
                        )}
                        <span className="time_date">
                            {' '}
                            {ticketMsg.timeDate} | {ticketMsg.dayDate}
                        </span>{' '}
                    </div>
                </div>
            )}
        </>
    );
}
export default function SupportDetailSection({
    supportData,
    supportTicketMessageData,
    sendMessage,
    message,
    handleMessage,
    handleOnFileChange,
    messageFile,
    handleRemoveFile,
    setCompleteModalShow,
}) {
    const { userInfo } = InitInfo();
    const uploadRef = createRef();

    return (
        <section className="cart-section">
            <Container>
                <div className="messaging">
                    <div className="inbox_msg">
                        <div className="inbox_people">
                            <div className="headind_srch">
                                <div className="recent_heading">
                                    <h4>Ticket ID</h4>
                                </div>
                                <div className="srch_bar">
                                    <div className="stylish-input-group">
                                        <input
                                            type="text"
                                            className="search-bar"
                                            placeholder="Search"
                                        />
                                        <span className="input-group-addon">
                                            <button type="button">
                                                {' '}
                                                <i
                                                    className="fa fa-search"
                                                    aria-hidden="true"
                                                />{' '}
                                            </button>
                                        </span>{' '}
                                    </div>
                                </div>
                            </div>
                            <div className="inbox_chat">
                                {supportData &&
                                    supportData.map((support) => (
                                        <Link to={`/support/${support.id}/detail`} key={support.id}>
                                            <div className="chat_list" key="support.id">
                                                <div className="chat_people">
                                                    <div className="chat_ib">
                                                        <h5>
                                                            {support.id}
                                                            <span
                                                                className="chat_date"
                                                                style={{ fontSize: '11px' }}
                                                            >
                                                                {support.time_created}
                                                            </span>
                                                        </h5>
                                                        <p>{support.subject}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                            </div>
                        </div>
                        <div className="mesgs">
                            <div className="msg_history">
                                {supportTicketMessageData &&
                                    supportTicketMessageData.map((ticketMsg) => (
                                        <MessageShow
                                            ticketMsg={ticketMsg}
                                            userInfo={userInfo}
                                            key={ticketMsg.id}
                                        />
                                    ))}
                            </div>
                            <div className="type_msg">
                                <div className="form-row">
                                    <div className="col-9">
                                        <div className="input_msg_write">
                                            <div>
                                                <input
                                                    type="text"
                                                    className="write_msg"
                                                    onChange={handleMessage}
                                                    placeholder="Type a message"
                                                    value={message}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: '-100px',
                                                    width: '45px',
                                                    height: '10px',
                                                }}
                                            >
                                                {messageFile && (
                                                    <div className="img-with-btn">
                                                        <img src={messageFile} />
                                                        <button
                                                            type="button"
                                                            onClick={handleRemoveFile}
                                                        >
                                                            <HighlightOffIcon fontSize="small" />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-1">
                                        <button
                                            className="msg_send_btn"
                                            type="button"
                                            onClick={sendMessage}
                                        >
                                            <SendIcon />
                                        </button>
                                    </div>
                                    <div className="col-1">
                                        <button
                                            className="msg_send_btn"
                                            type="button"
                                            onClick={() => {
                                                uploadRef.current.click();
                                            }}
                                        >
                                            <AttachmentIcon />
                                        </button>
                                        <input
                                            ref={uploadRef}
                                            type="file"
                                            name="file"
                                            onChange={handleOnFileChange}
                                            multiple
                                            style={{ display: 'none' }}
                                            accept="image/png, image/jpeg,,application/pdf"
                                        />
                                    </div>
                                    <div className="col-1">
                                        <button
                                            className="msg_send_btn"
                                            type="button"
                                            onClick={() => setCompleteModalShow(true)}
                                        >
                                            <AssignmentTurnedInIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}
