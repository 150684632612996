import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import SwipeableViews from 'react-swipeable-views';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import FindInArray from '../../../utils/FindInArray';
import EffectOne from '../comp/effects/EffectOne';
import FeatureProduct from '../comp/FeatureProduct';

const styles = {
    root: {
        padding: '0 30px',
        margin: '0 -15px',
    },
    slideContainer: {
        padding: '0 10px',
    },
    slide: {
        minHeight: 100,
        color: '#fff',
    },
};

export default function FeatureProductSection({ productData, featureProduct }) {
    const [index, setIndex] = useState(0);
    const countImage = featureProduct ? featureProduct.length : 3;

    const handleChangeIndex = (ind) => {
        setIndex(ind);
    };

    const FindArr = new FindInArray();
    FindArr.init(productData, 'id');

    return (
        <section className="mb-3" style={{ paddingTop: '20px' }}>
            <Container style={{ paddingBottom: '10px' }}>
                <div className="m-feature-show">
                    {featureProduct && (
                        <SwipeableViews
                            className={[
                                'position-set',
                                index === 0 ? 'start-set' : '',
                                countImage - index === 1 ? 'end-set' : '',
                            ].join(' ')}
                            style={styles.root}
                            slideStyle={styles.slideContainer}
                            onChangeIndex={handleChangeIndex}
                        >
                            {featureProduct &&
                                featureProduct.map((feature) => {
                                    const itemData = FindArr.find(feature.product_sl.toString());
                                    return (
                                        <div
                                            style={{ ...styles.slide }}
                                            key={`${feature.product_sl}`}
                                        >
                                            <EffectOne data={itemData} />
                                        </div>
                                    );
                                })}
                        </SwipeableViews>
                    )}
                </div>
                <div className="row m-feature-hide">
                    {featureProduct &&
                        featureProduct.map((feature) => {
                            const itemData = FindArr.find(feature.product_sl.toString());
                            return <FeatureProduct key={`${feature.product_sl}`} data={itemData} />;
                        })}
                </div>
            </Container>
        </section>
    );
}
