import React from 'react';
import { Button, Container } from 'react-bootstrap';
import FindInArray from '../../../utils/FindInArray';
import FlashSaleProduct from '../comp/FlashSaleProduct';

function FlashCount({ hour, min, sec }) {
    return (
        <>
            <Button variant="danger" className="product-detail-colorBtn">
                {hour}
            </Button>
            <Button variant="danger" className="product-detail-colorBtn">
                {min}
            </Button>
            <Button variant="danger" className="product-detail-colorBtn">
                {sec}
            </Button>
        </>
    );
}
function CampaignList({ campList: { campaignProducts }, productData }) {
    if (productData) {
        const FindArr = new FindInArray();
        FindArr.init(productData, 'id');

        return (
            <>
                {campaignProducts.map((item) => {
                    const itemData = FindArr.find(item.product_sl.toString());

                    return (
                        <div className="col mb-4 col-in-6 col-in-2-sm" key={`${item.product_sl}`}>
                            <FlashSaleProduct
                                itemData={itemData}
                                discount={campaignProducts.discount_percent}
                            />
                        </div>
                    );
                })}
            </>
        );
    }
    return null;
}
export default function FlashSaleProductSection({ list, productData }) {
    return (
        <section className="flash-product-section">
            <Container>
                <div className="form-row">
                    <div className="col-5 col-lg-2">
                        <h4 className="custom-typo">
                            <span>{list.campaign_title}</span>
                        </h4>
                    </div>

                    <div className="col-7 col-lg-8">
                        <div className="ends-title">
                            <span>
                                Ending in{' '}
                                <FlashCount hour={list.hour} min={list.min} sec={list.sec} />
                            </span>
                        </div>
                    </div>

                    <div className="col-12 col-lg-2">
                        <div className="view-all">
                            <span>
                                <Button size="sm pl-3 pr-3 btn-round-corner" variant="danger">
                                    View All
                                </Button>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="sm-padding">
                    <div className="row row-m-product">
                        <CampaignList campList={list} productData={productData} />
                    </div>
                </div>
            </Container>
        </section>
    );
}
