import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SupportDetailPage from '../app/support/pages/SupportDetailPage';
import SupportPage from '../app/support/pages/SupportPage';

function SupportRouters() {
    return (
        <Switch>
            <Route path="/support/:ticketId/detail">
                <SupportDetailPage />
            </Route>
            <Route path="/support">
                <SupportPage />
            </Route>
            <Route path="/support/add/ticket/:modals">
                <SupportPage />
            </Route>
        </Switch>
    );
}
export default SupportRouters;
