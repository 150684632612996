import React from 'react';
import { Container } from 'react-bootstrap';
import ProductData from '../../../../configs/ProductData';
import TopSellProduct from '../comp/TopSellProduct';

export default function TopSellProductSection() {
    return (
        <section className="top-sell-product-section">
            <Container>
                <h4 className="custom-typo">
                    <span>Best Sell</span>
                </h4>
                <div className="row row-m-product">
                    {ProductData.map((item) => (
                        <div
                            className="col mb-4 col-m-product top-sell-product-section-colI5"
                            key={item.id}
                        >
                            <TopSellProduct itemData={item} />
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    );
}
