/* eslint-disable react/jsx-no-duplicate-props */
import { faList, faTh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Tooltip } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import ProductPageDataMulti from '../comp/ProductPageDataMulti';
import ProductPageSingle from '../comp/ProductPageSingle';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));
function LabelOneItem({ data, keyName, handleToggle }) {
    const labelId = `checkbox-list-label-${keyName}`;

    const titleWithBadge = (
        <Badge
            edge="start"
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            color="primary"
        >
            <span>{`${data[keyName]?.title}`}</span>
        </Badge>
    );

    return (
        <ListItem role={undefined} dense button onClick={handleToggle(keyName)}>
            <ListItemText
                id={labelId}
                primary={titleWithBadge}
                primaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: '120%' } }}
            />
            <Badge
                edge="end"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                color="secondary"
            />
        </ListItem>
    );
}
function LabelTwoList({ classes, variants, keyName, handleToggleSub, data }) {
    const labelId = `checkbox-list-label-${keyName}`;

    if (keyName === 'color') {
        return (
            <div className="colors">
                {data.map((item) => (
                    <ColorsItem
                        labelId={labelId}
                        handleToggleSub={handleToggleSub}
                        item={item}
                        key={item}
                        variants={variants}
                        keyName={keyName}
                    />
                ))}
            </div>
        );
    }

    if (keyName === 'ageRange') {
        return (
            <div className="colors">
                {data.map((item) => (
                    <AgeItem
                        labelId={labelId}
                        handleToggleSub={handleToggleSub}
                        item={item}
                        key={item}
                        variants={variants}
                        keyName={keyName}
                    />
                ))}
            </div>
        );
    }

    return (
        <List component="div" disablePadding dense>
            {data.map((item) => (
                <LabelTwoItem
                    classes={classes}
                    labelId={labelId}
                    handleToggleSub={handleToggleSub}
                    item={item}
                    key={item}
                    variants={variants}
                    keyName={keyName}
                />
            ))}
        </List>
    );
}
function LabelTwoItem({ variants, keyName, handleToggleSub, item }) {
    const checked = variants[keyName] ? variants[keyName]?.indexOf(item) !== -1 : false;

    return (
        <Tooltip title={item}>
            <div className={['size-item', checked ? 'checked' : ''].join(' ')}>
                <button type="button" onClick={handleToggleSub(keyName, item)}>
                    {item}
                </button>
            </div>
        </Tooltip>
    );
}
function AgeItem({ variants, keyName, handleToggleSub, item }) {
    const checked = variants[keyName] ? variants[keyName]?.indexOf(item) !== -1 : false;

    return (
        <Tooltip title={item}>
            <div className={['age-item', checked ? 'checked' : ''].join(' ')}>
                <button type="button" onClick={handleToggleSub(keyName, item)}>
                    {item}
                </button>
            </div>
        </Tooltip>
    );
}
function ColorsItem({ variants, keyName, handleToggleSub, item }) {
    const checked = variants[keyName] ? variants[keyName]?.indexOf(item) !== -1 : false;

    return (
        <Tooltip title={item}>
            <div className={['color-item', checked ? 'checked' : ''].join(' ')}>
                <button
                    type="button"
                    style={{ backgroundColor: item }}
                    onClick={handleToggleSub(keyName, item)}
                />
            </div>
        </Tooltip>
    );
}
function ProductVarient({ data, handleToggle, handleToggleSub, variants }) {
    const classes = useStyles();

    return (
        <List className={classes.root}>
            {Object.keys(data).map((keyName) => (
                <div key={keyName}>
                    <LabelOneItem
                        data={data}
                        keyName={keyName}
                        handleToggle={handleToggle}
                        variants={variants}
                    />
                    {data[keyName] && variants && (
                        <LabelTwoList
                            keyName={keyName}
                            open={!!variants[keyName]}
                            classes={classes}
                            data={data[keyName].values}
                            handleToggleSub={handleToggleSub}
                            variants={variants}
                        />
                    )}
                </div>
            ))}
        </List>
    );
}
function CategoryProducts({ itemData, myClass }) {
    return (
        <div
            className={[
                'col mb-4 col-m-product',
                myClass === 'product-section-colI1'
                    ? 'product-section-colI1'
                    : 'product-section-colI3',
            ].join(' ')}
        >
            {myClass === 'product-section-colI1' ? (
                <LazyLoad throttle={200} height={300}>
                    <ProductPageSingle itemData={itemData} />
                </LazyLoad>
            ) : (
                <LazyLoad throttle={200} height={300}>
                    <ProductPageDataMulti itemData={itemData} />
                </LazyLoad>
            )}
        </div>
    );
}
function CategoryPageFilter({ myClass, handleColumOne, handleColumThree }) {
    return (
        <div className="col-12 col-m-product">
            <div className="page-filter-view">
                <div className="pageFilter">
                    <div className="page-filter-option">
                        <div className=" btn-group">
                            <Button
                                size="sm"
                                active={myClass === 'product-section-colI1'}
                                variant="outline-danger"
                                onClick={handleColumOne}
                            >
                                <FontAwesomeIcon icon={faList} />
                            </Button>
                            <Button
                                size="sm "
                                active={myClass === 'product-section-colI3'}
                                variant="outline-danger"
                                onClick={handleColumThree}
                            >
                                <FontAwesomeIcon icon={faTh} />
                            </Button>
                        </div>
                        <div className="btn-group float-right">
                            <Button size="sm " variant="outline-danger">
                                Best Sellers
                            </Button>
                            <Button size="sm " variant="outline-danger">
                                Newest
                            </Button>
                            <Button size="sm " variant="outline-danger">
                                Top Rated
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default function ProductCategory() {
    PageSetProperties('Category Products');
    const { shopData, initData } = InitInfo();

    const dispatch = useDispatch();
    const [show, setShow] = useState(!!initData.initId);
    const productData = shopData.products ? shopData.products : [];
    const productVariantList = shopData.productVariantList ? shopData.productVariantList : [];

    const history = useHistory();
    const [categoryId, setCategoryId] = useState(history.location.pathname.replace(/[^0-9]/g, ''));
    const [myClass, setMyClass] = useState('product-section-colI3');
    const [variants, setVariants] = useState([]);

    const handleToggle = (value) => () => {
        const newVariants = { ...variants };

        if (!variants[value]) {
            newVariants[value] = [];
        } else {
            delete newVariants[value];
        }

        setVariants(newVariants);
    };

    const handleToggleSub = (key, value) => () => {
        const currentIndex = variants[key]?.indexOf(value);
        const newVariants = { ...variants };

        if (currentIndex === -1) {
            newVariants[key].push(value);
        } else {
            newVariants[key]?.splice(currentIndex, 1);
        }

        setVariants(newVariants);
    };

    const handleColumThree = () => {
        setMyClass('product-section-colI3');
    };
    const handleColumOne = () => {
        setMyClass('product-section-colI1');
    };

    useEffect(() => {
        // effect

        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
                setVariants({ size: Array(0), color: Array(0), ageRange: Array(0) });
            })
            .catch((err) => {
                console.log(err);
            });

        return history.listen((location) => {
            setCategoryId(location.pathname.replace(/[^0-9]/g, ''));
        });
    }, [dispatch, history]);

    const FindArr = new FindInArray();
    FindArr.init(productData, 'id');

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu shopData={shopData} />
            <section className="PageSection">
                <Container>
                    <div className="page-banner" />

                    <div className="row">
                        <div className="d-none d-lg-block d-xl-block col-lg-3 col-lg-3">
                            <ProductVarient
                                data={productVariantList}
                                handleToggle={handleToggle}
                                handleToggleSub={handleToggleSub}
                                variants={variants}
                            />
                        </div>
                        <div className="col-sm-12 col-lg-9 col-lg-9">
                            <div className="row row-m-product">
                                <CategoryPageFilter
                                    myClass={myClass}
                                    handleColumOne={handleColumOne}
                                    handleColumThree={handleColumThree}
                                />
                                {productData &&
                                    productData
                                        .filter((obj) =>
                                            obj.categories.find(
                                                (o) => o.toString() === categoryId.toString()
                                            )
                                        )
                                        .map((item) => {
                                            if (item) {
                                                return (
                                                    <CategoryProducts
                                                        itemData={item}
                                                        myClass={myClass}
                                                        key={item.id}
                                                    />
                                                );
                                            }
                                            return <></>;
                                        })}
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <Footer />
        </Preview>
    );
}
