import React, { useEffect, useState } from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../section/FooterSection';
import HeaderSection from '../section/HeaderSection';

function AboutPage() {
    PageSetProperties('About');
    const dispatch = useDispatch();
    const { initData } = InitInfo();

    const [show, setShow] = useState(!!initData.initId);

    useEffect(() => {
        // effect

        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu />

            <section className="PageSection">
                <Container>
                    <div className="PageBanner">
                        <h5>About Us</h5>
                    </div>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>About</Breadcrumb.Item>
                    </Breadcrumb>
                    <div style={{ height: '200px' }} />
                </Container>
            </section>
            <Footer />
        </Preview>
    );
}
export default AboutPage;
