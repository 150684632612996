import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProductCategory from '../app/products/pages/ProductCategory';
import ProductDetailPage from '../app/products/pages/ProductDetailPage';
import ProductPage from '../app/products/pages/ProductPage';

function ProductRouters() {
    return (
        <Switch>
            <Route path="/category">
                <ProductCategory />
            </Route>

            <Route path="/products" exact>
                <ProductPage />
            </Route>

            <Route path="/products/:productId/:title">
                <ProductDetailPage />
            </Route>
        </Switch>
    );
}
export default ProductRouters;
