import {
    faFacebookF,
    faFlickr,
    faInstagram,
    faTwitter,
    // eslint-disable-next-line prettier/prettier
    faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { faAt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import rupkathaLogo from '../../../../assets/images/rupkatha-logo.png';
import EmailSubscribeBox from '../comp/EmailSubscribeBox';

function Footer() {
    return (
        <section className="footer-section">
            <Container>
                <div className="footer-section-item">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="overflow-auto">
                                <Link to="/">
                                    <img
                                        className="footer-section-logo"
                                        src={rupkathaLogo}
                                        alt=""
                                    />
                                </Link>
                            </div>
                            <div className="footer-section-address">
                                13,DIT ROAD, MALIBAGH CHOWDHURYPARA ( 4th & 5th floor) 1219 DHAKA,
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="footer-section-quick-link">
                                <h4>Quick Link</h4>
                                <div className="footer-section-quick-menu">
                                    <ul>
                                        <li>Men</li>
                                        <li>Women</li>
                                        <li>Kids</li>
                                        <li>About Us</li>
                                        <li>Contact Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="footer-section-social-link">
                                <div className="footer-section-search-box ">
                                    <EmailSubscribeBox />
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faPhone} /> +880 173001122 / +880
                                    18193322
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faAt} /> rupkotha@gmail.com
                                </div>
                                <div className="footer-section-social-link-item">
                                    <a href=".">
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>

                                    <a href=".">
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </a>

                                    <a href=".">
                                        <FontAwesomeIcon icon={faYoutube} />
                                    </a>

                                    <a href=".">
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </a>

                                    <a href=".">
                                        <FontAwesomeIcon icon={faFlickr} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <div className="footer-section-copy-right">
                Copyright © 2021 Rupkotha. All rights reserved.
            </div>
        </section>
    );
}
export default Footer;
