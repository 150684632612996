import { faHome, faKey, faQuestionCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

export default function UserInfo({ userInformation }) {
    return (
        <div className="profile-sidebar">
            <div className="profile-userpic">
                <div className="profile-user-circle">
                    <span>{userInformation.name ? userInformation.name.slice(0, 1) : '##'}</span>
                </div>
            </div>
            <div className="profile-usertitle">
                <div className="profile-usertitle-name">
                    {userInformation.name ? userInformation.name : 'Set Your Name'}
                </div>
                <div className="profile-usertitle-job">
                    {userInformation.default_contact
                        ? userInformation.default_contact
                        : userInformation.default_email}
                </div>
            </div>

            <hr />
            <div className="profile-usermenu">
                <ul>
                    <li className="active">
                        <Link to="/user/profile">
                            <FontAwesomeIcon icon={faHome} />
                            <span>My Account</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/user/delivered-order">
                            <FontAwesomeIcon icon={faKey} />
                            <span>My Orders</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/support">
                            <FontAwesomeIcon icon={faQuestionCircle} />
                            <span>Support</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/user/logout">
                            <FontAwesomeIcon icon={faSignOutAlt} />
                            <span>Logout</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}
