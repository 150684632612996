import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import UserAddressUpdateModal from '../modals/UserAddressUpdateModal';

export default function AddressUpdateModal() {
    const location = useLocation();
    const history = useHistory();
    const addressId = history.location.pathname.replace(/[^0-9]/g, '');
    const { userAddresses = [] } = InitInfo();

    const selectedAddress = userAddresses.filter(
        (address) => address.id.toString() === addressId.toString()
    )[0];

    return (
        <StepHandler pathname={location.pathname} exiturl="/user/address">
            <UserAddressUpdateModal
                addressId={addressId}
                selectedAddress={selectedAddress}
                path={`/user/address/${addressId}/update/`}
            />
            <div path="/user/address/update/two" />
        </StepHandler>
    );
}
