import React from 'react';
import { useLocation } from 'react-router-dom';
import StepHandler from '../../../utils/StepHandler';
import FamilyAddModal from '../modals/FamilyAddModal';

export default function FamilyAddModals() {
    const location = useLocation();
    return (
        <StepHandler pathname={location.pathname} exiturl="/user/profile/">
            <FamilyAddModal path="/user/profile/add/family/two" />
            <FamilyAddModal path="/user/profile/add/family/" />
        </StepHandler>
    );
}
