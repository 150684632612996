import ConfigApi from '../../../../configs/ConfigApi';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import ValidateEmail from '../../../utils/ValidateEmailPattern';
import ValidatePhone from '../../../utils/ValidatePhonePattern';
import ValidateUsername from '../../../utils/ValidateUsernamePattern';

export function loggingDetectOption(username, onValidateUserType, onValidationFailed) {
    if (ValidateEmail(username)) {
        console.log('Email Validated');

        if (typeof onValidateUserType === 'function') {
            onValidateUserType('email');
            return null;
        }
    } else if (ValidatePhone('880', username)) {
        console.log('Phone Validated');

        if (typeof onValidateUserType === 'function') {
            onValidateUserType('phone');
            return null;
        }
    } else if (ValidateUsername(username)) {
        console.log('Username Validated');

        if (typeof onValidateUserType === 'function') {
            onValidateUserType('username');
            return null;
        }
    }

    if (typeof onValidateUserType === 'function') {
        onValidateUserType(null);
        return null;
    }

    if (typeof onValidationFailed === 'function') {
        console.log('Username Pattern Validation Failed');

        onValidationFailed();
    }
    return null;
}

export function loggingChkMail(username, onSuccess, onError) {
    console.log(onError);

    // API Call for checking mail
    AxiosAuth.post(ConfigApi.API_LOGIN_EMAIL_CHK, {
        shop_id: process.env.REACT_APP_SHOP_ID,
        email: username,
    })
        .then((response) => {
            // console.log(response);
            const { data } = response;

            if (data.error === 0) {
                onSuccess(response);
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

export function loggingChkMailPassword(
    username,
    password,
    setLoginStatus,
    onPassVerified,
    onMultipleTry,
    onNetworkError
) {
    console.log(onMultipleTry, onNetworkError);

    // API Call for generate login key
    AxiosAuth.post(ConfigApi.API_LOGIN_EMAIL_BY_PASS, {
        shop_id: process.env.REACT_APP_SHOP_ID,
        email: username,
        password,
    })
        .then((response) => {
            // console.log(response);
            const { data } = response;

            if (data.error === 0) {
                onPassVerified(true, data.secure_key, setLoginStatus); // true
            } else {
                onPassVerified(false, null); // false
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

export function loggingChkMailOtp(
    username,
    otp,
    setLoginStatus,
    onPassVerified,
    onMultipleTry,
    onNetworkError
) {
    // API Call
    console.log(otp, setLoginStatus, onPassVerified, onMultipleTry, onNetworkError);

    // API Call for generate login key
    AxiosAuth.post(ConfigApi.API_LOGIN_EMAIL_BY_OTP, {
        shop_id: process.env.REACT_APP_SHOP_ID,
        email: username,
        otp,
    })
        .then((response) => {
            // console.log(response);
            const { data } = response;

            if (data.error === 0) {
                onPassVerified(true, data.secure_key, setLoginStatus); // true
            } else {
                onPassVerified(false, null); // false
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

export function loggingChkPhone(
    username,
    onPhoneOtp,
    onPhonePassword,
    onNewAccount,
    onNetworkError
) {
    // API Call
    console.log(username, onPhoneOtp, onPhonePassword, onNewAccount, onNetworkError);

    return new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_LOGIN_PHONE, {
            phone_no: username,
            country_code: '880',
            shop_id: process.env.REACT_APP_SHOP_ID,
        })
            .then((response) => {
                // resolve(response);
                console.log(response);

                if (response.data.error !== 0) {
                    //
                }
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function loggingSendPhoneOtp(
    username,
    onSendingSuccess,
    onSendingFailed,
    onMultipleTry,
    onNetworkError
) {
    // API Call Sending & Resending OTP
    console.log(username, onSendingSuccess, onSendingFailed, onMultipleTry, onNetworkError);
}

export function loggingChkPhoneOtp(
    username,
    otp,
    onOtpVerified,
    onOtpUnverified,
    onMultipleTry,
    onNetworkError
) {
    // API Call
    console.log(username, otp, onOtpVerified, onOtpUnverified, onMultipleTry, onNetworkError);
}

export function loggingChkPhonePassword(
    username,
    password,
    onPassVerified,
    onPassUnverified,
    onMultipleTry,
    onNetworkError
) {
    // API Call
    console.log(
        username,
        password,
        onPassVerified,
        onPassUnverified,
        onMultipleTry,
        onNetworkError
    );
}

export function loggingIn(dispatch, secretKey, onSuccessLogin) {
    localStorage.setItem('Secure-Access', secretKey);

    AppInitDispatch(dispatch, true).then((data) => {
        onSuccessLogin(data);
    });
}

export function loggingOut(dispatch, onSuccessLogout) {
    localStorage.removeItem('Secure-Access');

    AppInitDispatch(dispatch, true).then((data) => {
        onSuccessLogout(data);
    });
}

export default function UserAuthLogin() {
    return {};
}
