import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import ProductDataDispatch from '../../../dispatches/ProductDataDispatch';
import store from '../../../store';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import '../comp/effects/EffectTwoThumb.css';
import ProductDetailSection from '../section/ProductDetailSection';

function ProductDetailPage() {
    PageSetProperties('Detail');
    const dispatch = useDispatch();
    const { shopData, productCategoryList } = InitInfo();

    const { productDetails: productData } = store.getState();
    const { productId } = useParams();
    const thumbImages = productData?.images || [];

    const [show, setShow] = useState(productData.id === productId);

    useEffect(() => {
        AppInitDispatch(dispatch)
            .then(() => {
                // setShow(initStatus);

                ProductDataDispatch(productId, dispatch)
                    .then((productInfo) => {
                        if (productInfo.id) {
                            setShow(true);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, productCategoryList, productId]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu shopData={shopData} />
            <ProductDetailSection
                productData={productData}
                productCategoryList={productCategoryList || []}
                thumbImages={thumbImages}
                shopId={shopData?.shopInfo?.sl}
            />

            <Footer />
        </Preview>
    );
}
export default ProductDetailPage;
