/* eslint-disable no-useless-escape */
export default function ValidateUsernamePattern(username) {
    const found = username.match(/([a-z]{1})([a-z0-9\.\-\_]{5,14})([a-z0-9]{1})/gim);

    if (found !== null && found[0] === username) {
        return true;
    }

    return false;
}
