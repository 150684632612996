/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Provider } from 'react-redux';
import '../assets/css/bottom-navbar.css';
import '../assets/css/cart-box.css';
import '../assets/css/cart-section.css';
import '../assets/css/chat-box.css';
import '../assets/css/flush-section.css';
import '../assets/css/fonts.css';
import '../assets/css/footer-section.css';
import '../assets/css/menu-fashion.css';
import '../assets/css/menu-section.css';
import '../assets/css/modal-classic.css';
import '../assets/css/page-section.css';
import '../assets/css/profile-box.css';
import '../assets/css/responsive.css';
import '../assets/css/slider-section.css';
import '../assets/css/theme.css';
import '../assets/css/top-box.css';
import '../assets/css/top-option.css';
import '../assets/css/user-dashboard.css';
import '../assets/css/user-section.css';
import RouteHandler from './RouteHandler';
import store from './store';

function App() {
    return (
        <Provider store={store}>
            <RouteHandler />
        </Provider>
    );
}

export default App;
