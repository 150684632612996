import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import NoProductImg from '../../../../assets/images/NoProduct2.png';
import ConfigApi from '../../../../configs/ConfigApi';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import UserMenu from '../../users/comp/UserMenu';
import UserOrdersSection from '../section/UserOrdersSection';

const getOrdersData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_USER_ORDERS, {
            params,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function UserOrderTitleBar({ cattype }) {
    return (
        <div className="user-order-title">
            <div className="user-order-title-view">
                <Link to="/user/order" className={cattype === '' ? 'user-profile-btn-active' : ''}>
                    All Orders
                </Link>
                <Link
                    to="/user/processing-order"
                    className={cattype === 'processing' ? 'user-profile-btn-active' : ''}
                >
                    In Processing
                </Link>
                <Link
                    to="/user/delivered-order"
                    className={cattype === 'delivered' ? 'user-profile-btn-active' : ''}
                >
                    Delivered
                </Link>
                <Link
                    to="/user/canceled-order"
                    className={cattype === 'canceled' ? 'user-profile-btn-active' : ''}
                >
                    Canceled
                </Link>
            </div>
        </div>
    );
}
function OrderManagePage({ type }) {
    PageSetProperties('Orders');

    const dispatch = useDispatch();
    const history = useHistory();

    const { shopData, show } = InitInfo();
    const [memoAr, setMemoAr] = useState([]);
    const [memoItemAr, setMemoItemAr] = useState([]);
    const isLogin = localStorage.getItem('Secure-Access');

    // const { initData, userInformation } = store.getState();
    const setShow = useState()[1];
    useEffect(() => {
        // Collect API Data
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                if (isLogin) {
                    getOrdersData({ type })
                        .then((response) => {
                            setMemoAr(response.data.memoAr);
                            setMemoItemAr(response.data.memoItemAr);
                            setShow(initStatus);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    history.push('/registration?orders');
                }
                // Collect Orders Data
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, history, isLogin, setShow, type]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu shopData={shopData} />

            <section className="user-section">
                <Container>
                    <div className="row">
                        <UserMenu />
                        <div className="col-12 col-lg-9">
                            <div className="user-order">
                                <UserOrderTitleBar cattype={type} />

                                {memoAr.length !== 0 ? (
                                    memoAr &&
                                    memoAr.map((item) => {
                                        const memoItem = memoItemAr.filter(
                                            (mItem) =>
                                                mItem.memoId.toString() === item.id.toString()
                                        );

                                        return (
                                            <div className="user-order-section" key={item.id}>
                                                <div>
                                                    <UserOrdersSection
                                                        memoData={item}
                                                        memoItemData={memoItem}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="order-section-no-order">
                                        <div className="order-section-no-order-title">
                                            <h5>There Are No Orders.</h5>
                                            <h6>You can review and track your orders here.</h6>
                                        </div>
                                        <div className="order-section-no-order-img">
                                            <img src={NoProductImg} alt="No Product" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer />
        </Preview>
    );
}

export default OrderManagePage;
