/* eslint-disable global-require */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    lazyLoad: true,
    pauseOnHover: true,
};

export default function SliderSection() {
    const sliderData = useSelector((state) => state.shopData.sliderImages);

    return (
        <section className="slider-section">
            <div>
                <Slider {...settings}>
                    {sliderData &&
                        sliderData.map((sImg) => (
                            <div key={sImg.id}>
                                <img src={sImg.img_url} alt="" className="w-100" />
                            </div>
                        ))}
                </Slider>
            </div>
        </section>
    );
}
