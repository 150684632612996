const initialState = [];

const UserAddressReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADDRESS_INIT_SET': {
            return action.payload;
        }

        default:
            return state;
    }
};

export default UserAddressReducer;
