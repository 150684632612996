import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RegistrationPage from '../app/auth/RegistrationPage';

function AuthRouters() {
    return (
        <Switch>
            <Route path="/registration">
                <RegistrationPage />
            </Route>
        </Switch>
    );
}
export default AuthRouters;
