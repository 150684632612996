/* eslint-disable react/jsx-props-no-spreading */
import { TextField, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import SnackbarOpenDispatch from '../../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { UserModalFooter, UserModalHeader } from './comp/UserModalHeaderFooter';

const updateUserName = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_USER_UPDATE_NAME, params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

function userInfoInitSet(payload) {
    return {
        type: 'USER_INIT_SET',
        payload,
    };
}
const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};
export default function UserNameUpdateModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const theme = useTheme();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const { shopId } = InitInfo();
    const userInfo = useSelector((state) => state.userInformation);

    const [nameValue, setNameValue] = useState(userInfo.name);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleValue = (event) => {
        setNameValue(event.target.value);
    };

    const handleNextClick = () => {
        // console.dir(next);
        updateUserName({ name: nameValue, id: userInfo.id, shopId }, (response) => {
            if (response.data.error === 0) {
                dispatch(userInfoInitSet(response.data.userInfo));
                handelSnackbarOpen(dispatch, response.data.message);
                // --Update Store
                history.push(next);
            }
        });
    };

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <UserModalHeader prev={prev} step={step} exiturl={exiturl} title={nameValue} />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Update Name
                        </Typography>
                        <TextField
                            required
                            label="Name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter name"
                            value={nameValue}
                            onChange={handleValue}
                        />
                    </div>
                    <UserModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={nameValue}
                        handleNextClick={handleNextClick}
                        skip
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
