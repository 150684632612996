import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BuyNowButton from '../../../components/basic/BuyNowButton';
import Rating from '../../../components/basic/Rating';
import ThemeButton from '../../../components/basic/ThemeButton';

export default function ProductPageSingle(props) {
    const { itemData } = props;
    return (
        <Card className="product-section-card">
            <Card.Body className="product-section-card-body">
                <div className="row">
                    <div className="col-5">
                        <Link to={itemData.url}>
                            <Card.Img
                                className="product-section-imgBox"
                                src={`https://www.optimizee.xyz/images/${btoa(
                                    itemData.default_image
                                )},200,300.jpg`}
                            />
                        </Link>
                    </div>
                    <div className="col-4" style={{ borderLeft: '1px solid #ff000033' }}>
                        <Card.Text className="product-section-title-text">
                            <Link to={itemData.url}>{itemData.title}</Link>
                        </Card.Text>
                        <div className="product-section-pricing">
                            <div>
                                <h6>Price:</h6>
                                <strong>৳ {itemData.total_price}</strong>
                            </div>

                            <div style={{ float: 'left', marginTop: '30px' }}>
                                {itemData.variation_header !== null ? (
                                    <BuyNowButton
                                        myClassName="product-section-buyNow"
                                        icon={faCartArrowDown}
                                        size="sm"
                                        title="BUY NOW"
                                        id={itemData.id}
                                        productData={itemData}
                                    />
                                ) : (
                                    <ThemeButton
                                        myClassName="product-section-buyNow"
                                        icon={faCartArrowDown}
                                        size="sm"
                                        title="BUY NOW"
                                        id={itemData.id}
                                        selectedVariation={[]}
                                        isDisable={false}
                                        shopId={itemData.shopSl}
                                        successMessage="Add to Cart"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div
                            className="product-section-rating"
                            style={{ float: 'right', marginTop: '15px' }}
                        >
                            <h6>Rating:</h6>
                            <Rating />
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}
