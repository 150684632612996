/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prettier/prettier */
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import SnackbarOpenDispatch from '../../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import { UserModalHeader } from './comp/UserModalHeaderFooter';


function addressInitSet(payload) {
    return {
        type: 'ADDRESS_INIT_SET',
        payload,
    };
}
const setAddressData = () =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_USER_ADDRESS)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
const creatingAddress = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_USER_ADDRESS_CREATE, params)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
    const handelSnackbarOpen = (dispatch, message) => {
        SnackbarOpenDispatch(dispatch, message);
    };
function UserAddressCreateModal(props) {
    const { prev, step, exiturl } = props;
    const history = useHistory();

    const [name,setName]=useState('');
    const [phone,setPhone]=useState('');
    const [email, setEmail] = useState('');
    const [district,setDistrict]=useState('');
    const [city,setCity]=useState('');
    const [area,setArea]=useState('');
    const [address,setAddress]=useState('');
    const dispatch = useDispatch();
    const [selectedType, setSelectedType] = useState(1);

    const handleChangeType = (event) => {
        setSelectedType(event.target.value);
    };
    const handleValueChange=(event)=>{
       if (event.target.name==='name') {
        setName(event.target.value);
       }
       if (event.target.name==='phone') {
        setPhone(event.target.value);
       }
       if (event.target.name==='email') {
        setEmail(event.target.value);
       }
       if (event.target.name==='district') {
        setDistrict(event.target.value);
       }
       if (event.target.name==='city') {
        setCity(event.target.value);
       }
       if (event.target.name==='area') {
        setArea(event.target.value);
       }
       if (event.target.name==='address') {
        setAddress(event.target.value);
       }
    }
    const handleSubmit=()=>{
        
        creatingAddress({ name,phone,email,district,city,area,address,selectedType})
            .then((response) => {
                // DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    
                    setAddressData()
                        .then((responseAddress) => {
                            dispatch(addressInitSet(responseAddress.data));
                            handelSnackbarOpen(dispatch, response.data.message);

                            history.push(exiturl);                            
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return (
        <>
            <Modal
                size="lg"
                {...props}
                
                aria-labelledby="example-modal-sizes-title-lg"
            >
                
                <Modal.Body>
                <UserModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title="Create New Address"
                    />
                    <Form className="row">
                        <div className="col-12 col-lg-6">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Full Name</Form.Label>
                                <input className="form-control" name="name" value={name} onChange={handleValueChange} type="text" placeholder="Enter Full Name" />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Phone Number</Form.Label>
                                <input className="form-control" name="phone" value={phone} onChange={handleValueChange} type="text" placeholder="Enter Phone Number" />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <input className="form-control" name="email" value={email} onChange={handleValueChange} type="text" placeholder="Enter Email" />
                            </Form.Group>
                        </div>
                        <div className="col-12 col-lg-6">
                        <Form.Group controlId="formBasicEmail">
                                <Form.Label>Address</Form.Label>
                                <input className="form-control" name="address" value={address} onChange={handleValueChange} type="text" placeholder="Enter Address" />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>District</Form.Label>
                                <input className="form-control" name="district" value={district} onChange={handleValueChange} type="text" placeholder="Enter district" />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>City</Form.Label>
                                <input className="form-control" name="city" value={city} onChange={handleValueChange} type="text" placeholder="Enter City" />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Area</Form.Label>
                                <input className="form-control" name="area" value={area} onChange={handleValueChange} type="text" placeholder="Enter Area" />
                            </Form.Group>
                           
                            <RadioGroup aria-label="address type" name="address_type" value={selectedType} onChange={handleChangeType}>
                                <FormControlLabel value="1" control={<Radio />} label="Home" />
                                <FormControlLabel value="2" control={<Radio />} label="Office" />
                            </RadioGroup>
                            
                            <Button style={{ float: 'right' }} variant="primary" onClick={handleSubmit}>
                                Save
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default UserAddressCreateModal;


