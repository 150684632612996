import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loggingOut } from '../../system/models/UserAuthLogin';

function UsersLogout() {
    const dispatch = useDispatch();
    const history = useHistory();

    loggingOut(dispatch, () => {
        history.push('/');
    });

    return null;
}

export default UsersLogout;
