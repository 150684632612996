import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Rating from '../../../components/basic/Rating';
import ThemeButton from '../../../components/basic/ThemeButton';

export default function TopSellProduct({ itemData: { imgUrl, title, price, id } }) {
    return (
        <Card className="top-sell-product-section-card">
            <Link to="/product-detail">
                <Card.Img
                    className="product-section-imgBox"
                    src={`https://www.optimizee.xyz/images/${btoa(imgUrl)},200,300.jpg`}
                />
            </Link>

            <Card.Body className="top-sell-product-section-card-body">
                <Card.Text className="top-sell-product-section-title-text">
                    <Link to="/product-detail">{title}</Link>
                </Card.Text>

                <div className="top-sell-product-section-pricing">
                    <strong>৳ {price}</strong>

                    <ThemeButton
                        myClassName="top-sell-product-section-buyNow"
                        icon={faCartArrowDown}
                        size="sm"
                        title="BUY NOW"
                        id={id}
                        successMessage="Add to Cart"
                    />

                    <div className="top-sell-product-section-rating">
                        <Rating />
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}
