import ConfigApi from '../../configs/ConfigApi';
import store from '../store';
import AxiosAuth from '../utils/AxiosAuth';

function setProductDataToStore(payload) {
    return {
        type: 'PRODUCT_DETAILS_SET',
        payload,
    };
}

export function ProductDataUpdateDispatch(dispatch, newData, callback) {
    const { productDetails } = store.getState();
    const newProductDetails = { ...productDetails, ...newData };
    dispatch(setProductDataToStore(newProductDetails));

    if (typeof callback === 'function') {
        callback(newProductDetails);
    }
}

export default function ProductDataDispatch(id, dispatch) {
    const { productDetails } = store.getState();

    return new Promise((resolve, reject) => {
        if (productDetails.id !== parseInt(id, 10)) {
            AxiosAuth.get(ConfigApi.API_PRODUCT_DETAILS.replace(':productId', id), {
                params: {
                    shopId: process.env.REACT_APP_SHOP_ID,
                },
            })
                .then((response) => {
                    dispatch(setProductDataToStore(response.data));
                    resolve(response.data, response);
                })
                .catch((err) => {
                    reject(err);
                });
        } else if (typeof callback === 'function') {
            resolve(productDetails);
        }
    });
}
