/* eslint-disable radix */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

const OtpResend = ({ timeLimit, onResendClick }) => {
    const [count, setCount] = useState(timeLimit);

    useEffect(() => {
        if (count > 0) {
            let timeout = setTimeout(() => {
                setCount(count - 1);
            }, 1000);
            return () => {
                timeout = null;
            };
        }
        return null;
    }, [count]);

    if (count === 0) {
        return (
            <button
                type="button"
                style={{
                    float: 'right',
                    color: '#090',
                    border: '0px solid',
                    backgroundColor: 'transparent',
                }}
                onClick={onResendClick}
            >
                Send Again
            </button>
        );
    }

    return <span style={{ float: 'right', color: '#999' }}>Resend in {count}s</span>;
};

function UserOtpComp({ otp, otpKey, setOtp, onResendClick }) {
    const otpSize = 6;
    const ref = useRef();

    const valueAr = (otp || '').split('');

    const setOtpValue = (ev) => {
        const newVal = ev.target.value.split('');
        valueAr.push(newVal);
        if (valueAr.length > 6) valueAr.length = 6;
        setOtp(valueAr.join(''));
    };

    const removeOtpValue = (ev) => {
        if (ev.keyCode === 8 && valueAr.length > 0) {
            valueAr.length -= 1;
            setOtp(valueAr.join(''));
        }
    };

    const onFocusAction = (ev) => {
        const key = ev.target.getAttribute('data-position');

        if (parseInt(key) < otpSize - 1) {
            valueAr.length = key;
            setOtp(valueAr.join(''));
        }
    };

    const onPasteAction = (event) => {
        const newVal = (event.clipboardData || window.clipboardData).getData('text').split('');
        valueAr.push(...newVal);

        if (valueAr.length > 6) valueAr.length = 6;
        setOtp(valueAr.join(''));
    };

    useEffect(() => {
        const focusPosition = valueAr.length > otpSize - 1 ? otpSize - 1 : valueAr.length;
        ref.current.children[focusPosition].focus();
    }, [valueAr.length]);

    const Inp = () => {
        const Inputs = [];
        for (let x = 0; x < otpSize; x += 1) {
            // const element = array[x];

            Inputs.push(
                <input
                    type="text"
                    maxLength="1"
                    data-position={x}
                    value={(valueAr[x] || '').toUpperCase()}
                    onChange={setOtpValue}
                    onKeyUp={removeOtpValue}
                    onFocus={onFocusAction}
                    onPaste={onPasteAction}
                />
            );
        }

        return Inputs;
    };

    return (
        <div className="input-area">
            <div className="label">
                <OtpResend timeLimit={60} key={otpKey} onResendClick={onResendClick} />
                OTP Code Here
            </div>
            <div className="input otp" ref={ref}>
                <Inp />
            </div>
        </div>
    );
}

export default UserOtpComp;
