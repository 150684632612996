export const DOMAIN = process.env.REACT_APP_API_URL;

const ConfigApi = {
    API_APP_INIT: `${DOMAIN}/application/init`,

    API_SITE_DATA: `${DOMAIN}/rest/site-data.json`,
    // --Image Upload
    API_IMAGE_UPLOAD: `${DOMAIN}/application/image/upload`,

    // Login Page
    API_REG_IN: `${DOMAIN}/reg-user/`,
    API_LOGGING_IN: `${DOMAIN}/login-user/`,

    API_LOGIN_EMAIL_CHK: `${DOMAIN}/login/email/check/`,
    API_LOGIN_EMAIL_BY_PASS: `${DOMAIN}/login/email/by-pass/`,
    API_LOGIN_EMAIL_OTP: `${DOMAIN}/login/email/otp/`,
    API_LOGIN_EMAIL_BY_OTP: `${DOMAIN}/login/email/by-otp/`,

    API_LOGIN_PHONE: `${DOMAIN}/login/phone/`,
    API_LOGIN_PHONE_OTP: `${DOMAIN}/login/phone/otp/`,
    API_LOGIN_PHONE_BY_OTP: `${DOMAIN}/login/phone/by-otp/`,
    API_LOGIN_PHONE_BY_PASS: `${DOMAIN}/login/phone/by-pass/`,

    // Product Module
    API_PRODUCTS: `${DOMAIN}/products/list/`,
    API_PRODUCT_DETAILS: `${DOMAIN}/application/product/:productId/info`,
    API_PRODUCT_TITLE_UPDATE: `${DOMAIN}/products/:productId/update/title`,
    API_PRODUCT_CAT_UPDATE: `${DOMAIN}/products/:productId/update/category`,
    API_PRODUCT_DES_UPDATE: `${DOMAIN}/products/:productId/update/description`,
    API_PRODUCT_PRICE_UPDATE: `${DOMAIN}/products/:productId/update/price`,
    API_PRODUCT_IMG_UPDATE: `${DOMAIN}/products/:productId/update/images`,
    API_PRODUCT_VARIATION_HEAD_UPDATE: `${DOMAIN}/products/:productId/update/variation-header`,

    // --Category Paths
    API_CATEGORY_LIST: `${DOMAIN}/products/category`,

    // Cart  Module
    API_CART: `${DOMAIN}/application/activity/cart/info`,
    API_CART_STATE_UPDATE: `${DOMAIN}/application/activity/cart/state-update`,

    // Invoice Module
    API_INVOICE: `${DOMAIN}/application/activity/invoice`,
    API_INVOICE_CREATE: `${DOMAIN}/application/activity/invoice/add`,
    // User Module
    API_USER_UPDATE_NAME: `${DOMAIN}/application/activity/user/update-name`,
    API_USER_UPDATE_EMAIL: `${DOMAIN}/application/activity/user/update-email`,
    API_USER_UPDATE_CONTACT: `${DOMAIN}/application/activity/user/update-contact`,
    API_USER_UPDATE_PASSWORD: `${DOMAIN}/application/activity/user/update-password`,

    API_USER_ADDRESS: `${DOMAIN}/application/activity/address`,
    API_USER_ADDRESS_CREATE: `${DOMAIN}/application/activity/address/add`,
    API_USER_ADDRESS_UPDATE: `${DOMAIN}/application/activity/address/:addressId/update`,
    API_USER_FAMILY: `${DOMAIN}/application/activity/family`,
    API_USER_ADD_FAMILY: `${DOMAIN}/application/activity/add/family`,

    // Order Module
    API_USER_ORDERS: `${DOMAIN}/application/activity/orders`,
    API_USER_ORDERS_RECENT: `${DOMAIN}/application/activity/orders/recent`,

    // Support Module
    API_SUPPORT: `${DOMAIN}/application/activity/user/support`,
    API_SUPPORT_CREATE: `${DOMAIN}/application/activity/user/support/create`,

    API_SUPPORT_TICKET_MESSAGE: `${DOMAIN}/application/activity/user/support/:shopId/ticket/:ticketId/message`,
    API_SUPPORT_TICKET_MESSAGE_CREATE: `${DOMAIN}/application/activity/user/support/ticket/message/create`,
    API_SUPPORT_TICKET_COMPLETE: `${DOMAIN}/application/activity/user/support/:shopId/ticket/:ticketId/complete`,
};

export default ConfigApi;
