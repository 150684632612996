import React from 'react';
import { Container } from 'react-bootstrap';
import NewProduct from '../comp/NewProduct';

export default function NewProductSection({ productData }) {
    return (
        <section className="new-product-section">
            <Container>
                <h4 className="custom-typo">
                    <span>New Products</span>
                </h4>
                <div className="row row-m-product">
                    {productData &&
                        productData.slice(0, 10).map((item) => (
                            <div
                                className="col mb-4 col-m-product new-product-section-colI5"
                                key={item.id}
                            >
                                <NewProduct itemData={item} />
                            </div>
                        ))}
                </div>
            </Container>
        </section>
    );
}
