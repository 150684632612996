/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
import React from 'react';
import { Container } from 'react-bootstrap';
import ProductItemData from '../comp/Products';

function ProductSection({ productData }) {
    return (
        <section className="product-section">
            <Container>
                <h4 className="custom-typo">
                    <span>Products</span>
                </h4>
                <div className="row row-m-product">
                    {productData &&
                        productData.slice(0, 10).map((item) => (
                            <div
                                className="col mb-4 col-m-product product-section-colI5"
                                key={item.id}
                            >
                                <ProductItemData itemData={item} />
                            </div>
                        ))}
                </div>
            </Container>
        </section>
    );
}

export default ProductSection;
