import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chip from '@material-ui/core/Chip';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ThemeButton from '../../../../components/basic/ThemeButton';

function ProductSize({ size, variants, itemName, handleToggleSub }) {
    const checked = variants[itemName] === size;
    return (
        <Button
            variant={checked ? 'primary' : 'outline-info'}
            className={['product-detail-sizeBtn', checked ? 'checked' : ''].join(' ')}
            onClick={handleToggleSub(itemName, size)}
        >
            {size}
        </Button>
    );
}

function ProductAgeRange({ age, variants, itemName, handleToggleSub }) {
    const checked = variants[itemName] === age;
    return (
        <Button
            size="sm"
            variant={checked ? 'primary' : 'outline-info'}
            className={['product-detail-ageBtn mr-2', checked ? 'checked' : ''].join(' ')}
            onClick={handleToggleSub(itemName, age)}
        >
            {age}
        </Button>
    );
}

function ProductColor({ color, variants, itemName, handleToggleSub }) {
    const checked = variants[itemName] === color;

    return (
        <div className={['color-item', checked ? 'checked' : ''].join(' ')} style={{ margin: 0 }}>
            <button
                type="button"
                style={{ backgroundColor: color }}
                onClick={handleToggleSub(itemName, color)}
            />
        </div>
    );
}

function ShowProductDetailsHeader({ data }) {
    return (
        <div className="product-detail-header">
            <strong className="product-detail-title">{data.title}</strong>
            <br />
            {data.reward && (
                <small style={{ color: 'yellowgreen' }}>
                    You will get {(data.reward * data.price_purchase) / 100} Points
                </small>
            )}
            <div className="ProductDetailBorder" />
        </div>
    );
}

function ShowCategories({ data, productCategoryList }) {
    return (
        <div className="product-detail-category">
            <h6>Category :</h6>

            {data.categories
                ? data.categories.map((cat) => (
                      <Chip
                          label={
                              productCategoryList &&
                              productCategoryList.filter((e) => e.id === cat).map((en) => en.title)
                          }
                          color="secondary"
                          size="small"
                          variant="outlined"
                          className="chip-style-category"
                          style={{ margin: '5px' }}
                          key={cat}
                      />
                  ))
                : 'Not Set'}
        </div>
    );
}

function ShowPrices({ data }) {
    return (
        <div className="product-detail-price">
            <h6>Price :</h6>
            <strong>৳ {data?.mrp || '0.00'}</strong>
            {data.price_purchase !== data.mrp ? (
                <span>৳ {data.price_purchase?.toFixed(2)} </span>
            ) : (
                ''
            )}
        </div>
    );
}

function ProductVariantOption({ itemName, itemData, variants, handleToggleSub }) {
    if (itemName === 'color') {
        return (
            <div className="product-detail-color">
                <h6 className="product-detail-size-title">Color:</h6>
                {itemData.map((item) => (
                    <ProductColor
                        color={item}
                        key={item}
                        itemName={itemName}
                        variants={variants}
                        handleToggleSub={handleToggleSub}
                    />
                ))}
            </div>
        );
    }

    if (itemName === 'size') {
        return (
            <div className="product-detail-size">
                <h6 className="product-detail-size-title">Size:</h6>
                {itemData.map((item) => (
                    <ProductSize
                        size={item}
                        key={item}
                        itemName={itemName}
                        variants={variants}
                        handleToggleSub={handleToggleSub}
                    />
                ))}
            </div>
        );
    }

    return (
        <div className="product-detail-age">
            <h6 className="product-detail-size-title">Age:</h6>
            {itemData.map((item) => (
                <ProductAgeRange
                    age={item}
                    key={item}
                    itemName={itemName}
                    variants={variants}
                    handleToggleSub={handleToggleSub}
                />
            ))}
        </div>
    );
}

function ProductVariant({ data, variants, handleToggleSub }) {
    if (data) {
        return Object.keys(data).map((item) => (
            <div key={item}>
                <ProductVariantOption
                    handleToggleSub={handleToggleSub}
                    itemName={item}
                    itemData={data[item]}
                    variants={variants}
                />
            </div>
        ));
    }

    return null;
}

function ShowShareButtons() {
    return (
        <div className="product-detail-social-link">
            <FacebookIcon className="link" />
            <InstagramIcon className="link" />
            <TwitterIcon className="link" />
            <LinkedInIcon className="link" />
        </div>
    );
}
function QtyOption({ handleChange, handleQtyMinus, handleQtyPlus, newQuantity }) {
    return (
        <>
            <FontAwesomeIcon onClick={handleQtyMinus} size="sm" icon={faMinus} />
            <input
                style={{ border: 'none', width: '50px', textAlign: 'center' }}
                onChange={handleChange}
                value={newQuantity}
            />
            <FontAwesomeIcon onClick={handleQtyPlus} size="sm" icon={faPlus} />
        </>
    );
}
export default function ProductOverviewInfo({ data, productCategoryList, shopId }) {
    const [variants, setVariants] = useState({});
    const [newQuantity, setNewQuantity] = useState(1);
    const handleToggleSub = (key, value) => () => {
        setVariants({ ...variants, [key]: value });
        setNewQuantity(1);
    };

    const handleQtyPlus = () => {
        setNewQuantity(newQuantity + 1);
    };
    const handleQtyMinus = () => {
        if (newQuantity <= 1) {
            setNewQuantity(1);
        } else {
            setNewQuantity(newQuantity - 1);
        }
    };

    const handleChange = (e) => {
        setNewQuantity(e.target.value);
    };
    return (
        <>
            <ShowProductDetailsHeader data={data} />

            <ShowCategories data={data} productCategoryList={productCategoryList} />

            <ShowPrices data={data} />

            <ProductVariant
                handleToggleSub={handleToggleSub}
                variants={variants}
                data={data?.variation_header || {}}
                handleChange={handleChange}
            />
            <div>
                <div className="product-detail-color">
                    <h6 className="product-detail-size-title">Qty:</h6>
                    <QtyOption
                        handleChange={handleChange}
                        handleQtyMinus={handleQtyMinus}
                        handleQtyPlus={handleQtyPlus}
                        newQuantity={newQuantity}
                    />
                </div>
            </div>

            <ThemeButton
                myClass="product-detail-optionBtn"
                size="xs"
                title="BUY NOW"
                id={data.id}
                selectedVariation={variants}
                isDisable={
                    Object.keys(data?.variation_header || []).length !==
                    Object.keys(variants).length
                }
                shopId={shopId}
                newQuantity={newQuantity}
                successMessage="Add to Cart"
            />

            <ShowShareButtons />
        </>
    );
}
