import {
    faFacebookF,
    faFlickr,
    faInstagram,
    faTwitter,
    // eslint-disable-next-line prettier/prettier
    faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function TopHeader({ shopData }) {
    return (
        <div className="row">
            <div className="col-6 social-links">
                <a href=".">
                    <FontAwesomeIcon icon={faFacebookF} />
                </a>

                <a href=".">
                    <FontAwesomeIcon icon={faTwitter} />
                </a>

                <a href=".">
                    <FontAwesomeIcon icon={faYoutube} />
                </a>

                <a href=".">
                    <FontAwesomeIcon icon={faInstagram} />
                </a>

                <a href=".">
                    <FontAwesomeIcon icon={faFlickr} />
                </a>
            </div>
            <div className="col-6 helpline-box">
                <span className="helpline">
                    Call Us{' '}
                    {shopData?.shopInfo?.contact_number ? (
                        <a
                            href={`tel:${shopData.shopInfo.contact_number}`}
                            style={{ color: '#ff8a57' }}
                        >
                            {shopData.shopInfo.contact_number}
                        </a>
                    ) : (
                        'Contact Not Set'
                    )}
                </span>
            </div>
        </div>
    );
}
