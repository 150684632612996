import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import FavImgBl from '../../../../assets/images/favorite-bl.png';
import FavImgRd from '../../../../assets/images/favorite-rd.png';
import OrderImgBl from '../../../../assets/images/my-order-bl.png';
import OrderImgRd from '../../../../assets/images/my-order-rd.png';
import RevImgBl from '../../../../assets/images/review-bl.png';
import RevImgRd from '../../../../assets/images/review-rd.png';
import UserPImage from '../../../../assets/images/user-image.png';
import InitInfo from '../../../utils/InitInfo';

function UserMenu() {
    const { userInfo } = InitInfo();

    const history = useHistory();
    const lPath = history.location.pathname;
    const [orderImg, setSrderImg] = useState(OrderImgBl);
    const [wishImg, setWishImg] = useState(FavImgBl);

    const [revImg, setRevImg] = useState(RevImgBl);

    const [supImg, setSupImg] = useState(RevImgBl);

    const handleHover = (e) => {
        if (e === 'order') {
            setSrderImg(OrderImgRd);
        }
        if (e === 'wish') {
            setWishImg(FavImgRd);
        }
        if (e === 'review') {
            setRevImg(RevImgRd);
        }
        if (e === 'support') {
            setSupImg(RevImgRd);
        }
    };
    const handleHoverLeave = (e) => {
        if (e === 'order') {
            setSrderImg(OrderImgBl);
        }
        if (e === 'wish') {
            setWishImg(FavImgBl);
        }
        if (e === 'review') {
            setRevImg(RevImgBl);
        }
        if (e === 'support') {
            setSupImg(RevImgBl);
        }
    };
    return (
        <div className="d-none d-lg-block d-xl-block col-lg-3 col-xl-3 pb-2">
            <div className="user-menu-section">
                <div className="user-menu-profile">
                    <div className="user-menu-profile-img">
                        <img src={UserPImage} alt="User" />
                    </div>
                    <div className="user-menu-profile-title">
                        <h3>{userInfo.name}</h3>
                        <p>{userInfo.default_email}</p>
                    </div>
                    <div className="user-menu-profile-btn">
                        <Link to="/user/profile">My Profile</Link>
                    </div>
                </div>
                <div className="user-menu-profile-divider" />

                <div className={lPath === '/user/order' ? 'user-menu2-active' : 'user-menu2'}>
                    <h6>
                        <Link
                            className="user-menu-profile-link"
                            onMouseOver={() => handleHover('order')}
                            onMouseLeave={() => handleHoverLeave('order')}
                            to="/user/order"
                        >
                            <span className={lPath === '/user/order' ? 'span-active' : ''}>
                                <img
                                    src={lPath === '/user/order' ? OrderImgRd : orderImg}
                                    alt="Order"
                                />
                                My Order
                            </span>
                        </Link>
                    </h6>
                </div>
                <div className={lPath === '/user/wishlist' ? 'user-menu2-active' : 'user-menu2'}>
                    <h6>
                        <Link
                            className="user-menu-profile-link"
                            onMouseOver={() => handleHover('wish')}
                            onMouseLeave={() => handleHoverLeave('wish')}
                            to="/user/wishlist"
                        >
                            <span className={lPath === '/user/wishlist' ? 'span-active' : ''}>
                                <img
                                    src={lPath === '/user/wishlist' ? FavImgRd : wishImg}
                                    alt="Fav"
                                />
                                My Favourite
                            </span>
                        </Link>
                    </h6>
                </div>
                <div className={lPath === '/user/reviews' ? 'user-menu2-active' : 'user-menu2'}>
                    <h6>
                        <Link
                            className="user-menu-profile-link"
                            onMouseOver={() => handleHover('review')}
                            onMouseLeave={() => handleHoverLeave('review')}
                            to="/user/reviews"
                        >
                            <span className={lPath === '/user/reviews' ? 'span-active' : ''}>
                                <img
                                    src={lPath === '/user/reviews' ? RevImgRd : revImg}
                                    alt="Fav"
                                />
                                My Review
                            </span>
                        </Link>
                    </h6>
                </div>
                <div className={lPath === '/support' ? 'user-menu2-active' : 'user-menu2'}>
                    <h6>
                        <Link
                            className="user-menu-profile-link"
                            onMouseOver={() => handleHover('support')}
                            onMouseLeave={() => handleHoverLeave('support')}
                            to="/support"
                        >
                            <span className={lPath === '/support' ? 'span-active' : ''}>
                                <img src={lPath === '/support' ? RevImgRd : supImg} alt="Fav" />
                                Support
                            </span>
                        </Link>
                    </h6>
                </div>
            </div>
        </div>
    );
}
export default UserMenu;
