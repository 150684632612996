import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Rating from '../../../components/basic/Rating';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import UserMenu from '../comp/UserMenu';

function OrderDetailRelatedProduct({ products }) {
    return (
        <div className="order-section-detail-related-product">
            <div className="row">
                {products.slice(0, 12).map((row) => (
                    <div className="col-4">
                        <div className="order-section-detail-related-product-list">
                            <div className="">
                                <img
                                    className="product-section-imgBox"
                                    src={`https://www.optimizee.xyz/images/${btoa(
                                        row.default_image
                                    )},200,300.jpg`}
                                    alt={row.title}
                                />
                            </div>
                            <div className="order-section-detail-related-product-list-title">
                                {row.title}
                            </div>
                            <div className="order-section-detail-related-product-price">
                                <strong>৳ {row.total_price}</strong>
                                <span className="offer" />
                                <div className="product-section-rating">
                                    <Rating />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
function UserWishlistPage() {
    PageSetProperties('Wishlist');
    const { initData, products } = InitInfo();

    const dispatch = useDispatch();
    const [show, setShow] = useState(!!initData.initId);
    const history = useHistory();
    const { invoiceId } = useParams();
    const isLogin = localStorage.getItem('Secure-Access');

    React.useEffect(() => {
        // Collect API Data
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                if (isLogin) {
                    setShow(initStatus);
                } else {
                    history.push('/registration');
                }
            })
            .catch((err) => {
                console.log(err);
            });
        // Collect invoice data
    }, [dispatch, history, invoiceId, isLogin]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu />

            <section className="user-section">
                <Container>
                    <div className="row">
                        <UserMenu />
                        <div className="col-12 col-lg-9">
                            <div className="order-section-detail">
                                <OrderDetailRelatedProduct products={products} />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer />
        </Preview>
    );
}

export default UserWishlistPage;
