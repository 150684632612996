import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SupportPage from '../app/support/pages/SupportPage';
import AboutPage from '../app/system/pages/AboutPage';
import ContactPage from '../app/system/pages/ContactPage';
import IndexPage from '../app/system/pages/IndexPage';

function SystemRouters() {
    return (
        <Switch>
            <Route path="/" exact>
                <IndexPage />
            </Route>

            <Route path="/about">
                <AboutPage />
            </Route>

            <Route path="/contact">
                <ContactPage />
            </Route>
            <Route path="/support">
                <SupportPage />
            </Route>
            <Route path="/support/add/ticket/:modals">
                <SupportPage />
            </Route>
        </Switch>
    );
}
export default SystemRouters;
