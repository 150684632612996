import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import CheckoutSection from '../section/CheckoutSection';

function ProductCheckoutPage() {
    PageSetProperties('Checkout');
    const dispatch = useDispatch();
    const { shopData, initData, products, shopId } = InitInfo();
    const history = useHistory();

    const [show, setShow] = useState(!!initData.initId);
    const isLogin = localStorage.getItem('Secure-Access');
    React.useEffect(() => {
        // Collect API Data
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                if (isLogin) {
                    setShow(initStatus);
                } else {
                    history.push('/registration?checkout');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, history, isLogin]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu shopData={shopData} />
            <CheckoutSection productData={products} shopId={shopId} />
            <Footer />
        </Preview>
    );
}
export default ProductCheckoutPage;
