import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProductCartPage from '../app/cart/pages/ProductCartPage';
import ProductCheckoutPage from '../app/cart/pages/ProductCheckoutPage';
import ProductInvoicePage from '../app/cart/pages/ProductInvoicePage';
import ProductInvoicePrint from '../app/cart/pages/ProductInvoicePrint';

function CartRouters() {
    return (
        <Switch>
            <Route path="/invoice/:invoiceId/print">
                <ProductInvoicePrint />
            </Route>
            <Route path="/invoice/:invoiceId">
                <ProductInvoicePage />
            </Route>

            <Route path="/cart">
                <ProductCartPage />
            </Route>

            <Route path="/checkout">
                <ProductCheckoutPage />
            </Route>
        </Switch>
    );
}
export default CartRouters;
