import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function priceCalculator(cartProducts) {
    let tItem = 0;

    cartProducts?.forEach((itemAr) => {
        itemAr.productVariation.forEach((variation) => {
            tItem += variation.quantity;
        });
    });

    return { totalItem: tItem };
}

function CartOption() {
    // const totalQty = useSelector((state) =>
    //     state.cartProducts.map((product) => ArraySum(product.productVariation, 'quantity'))
    // );
    const { totalItem } = useSelector((state) => {
        const { cartProducts } = state;
        return priceCalculator(cartProducts);
    });
    const isLogin = localStorage.getItem('Secure-Access');
    const url = isLogin ? '/cart' : '/registration?cart';
    return (
        <div className="top-options">
            <Link to={url} className="top-icon" role="button">
                <FontAwesomeIcon icon={faShoppingCart} size="lg" className="user-operation-icon" />
                {totalItem > 0 && <span className="cart-item">{totalItem}</span>}
                <div>Cart</div>
            </Link>
        </div>
    );
}

export default CartOption;
