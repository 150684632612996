/* eslint-disable global-require */
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BuyNowButton from '../../../components/basic/BuyNowButton';
import Rating from '../../../components/basic/Rating';
import ThemeButton from '../../../components/basic/ThemeButton';

// const addToCart = (productId) => ({
//     type: 'ADD_TO_CART',
//     payload: {
//         productId,
//     },
// });

function ProductItemData({ itemData }) {
    return (
        <Card className="product-section-card">
            <span className="ProductnotifyBadge">Top Rated</span>
            {itemData.stock <= 0 ? (
                <img
                    className="RubberStamp"
                    src={require('../../../../assets/images/stock_out.png').default}
                    alt=""
                />
            ) : (
                ''
            )}

            <Link to={itemData.url}>
                <Card.Img
                    className="product-section-imgBox"
                    src={`https://www.optimizee.xyz/images/${btoa(
                        itemData.default_image
                    )},200,300.jpg`}
                />
            </Link>

            <Card.Body className="product-section-card-body">
                <Card.Text className="product-section-title-text">
                    <Link to={itemData.url}>{itemData.title}</Link>
                </Card.Text>
                <div className="product-section-pricing">
                    <strong>৳ {itemData.total_price}</strong>
                    {itemData.variation_header !== null ? (
                        <BuyNowButton
                            myClassName="product-section-buyNow"
                            icon={faCartArrowDown}
                            size="sm"
                            title="BUY NOW"
                            id={itemData.id}
                            productData={itemData}
                        />
                    ) : (
                        <ThemeButton
                            myClassName="product-section-buyNow"
                            icon={faCartArrowDown}
                            size="sm"
                            title="BUY NOW"
                            id={itemData.id}
                            selectedVariation={[]}
                            isDisable={false}
                            shopId={itemData.shopSl}
                            successMessage="Add to Cart"
                        />
                    )}

                    <div className="product-section-rating">
                        <Rating />
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default ProductItemData;
