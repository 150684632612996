import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import store from '../../../store';
import OptionBox from './OptionBox';
import UserInfo from './UserInfo';

export default function UserOption() {
    const [show, setShow] = React.useState(false);
    const { userInformation } = store.getState();

    const handleHide = () => {
        setShow(false);
    };

    const isLogin = localStorage.getItem('Secure-Access');
    return (
        <div className="top-options">
            {!isLogin ? (
                <Link
                    role="button"
                    to="/registration"
                    className={['top-icon', show ? 'box-showing' : ''].join(' ')}
                >
                    <FontAwesomeIcon icon={faUser} size="lg" className="user-operation-icon" />
                    <div>Account</div>
                </Link>
            ) : (
                <div
                    role="button"
                    tabIndex={-1}
                    className={['top-icon', show ? 'box-showing' : ''].join(' ')}
                    onClick={() => {
                        setShow(true);
                    }}
                >
                    <FontAwesomeIcon icon={faUser} size="lg" className="user-operation-icon" />
                    <div>Account</div>
                </div>
            )}

            <OptionBox show={show} handleHide={handleHide}>
                <UserInfo userInformation={userInformation} />
            </OptionBox>
        </div>
    );
}
