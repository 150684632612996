import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import OrderDetailsPage from '../app/orders/pages/OrderDetailsPage';
import OrderManagePage from '../app/orders/pages/OrderManagePage';
import UserAddressPage from '../app/users/pages/UserAddressPage';
import UserPaymentPage from '../app/users/pages/UserPaymentPage';
import UserProfilePage from '../app/users/pages/UserProfilePage';
import UserReviewPage from '../app/users/pages/UserReviewPage';
import UsersLogout from '../app/users/pages/UsersLogout';
import UserVoucherPage from '../app/users/pages/UserVoucherPage';
import UserWishlistPage from '../app/users/pages/UserWishlistPage';
import UsersDashboard from '../app/users/section/UsersDashboard';

function UserRouters() {
    const location = useLocation();

    return (
        <Switch>
            <Route path="/user/profile">
                <UserProfilePage key={location.key} />
            </Route>

            <Route path="/user/profile/add/family/:modals">
                <UserProfilePage />
            </Route>

            <Route path="/user/profile/update/:modals">
                <UserProfilePage />
            </Route>

            <Route path="/user/address">
                <UserAddressPage />
            </Route>

            <Route path="/user/address/:addressId/update/:modals">
                <UserAddressPage />
            </Route>
            <Route path="/user/address/create/:modals">
                <UserAddressPage />
            </Route>

            <Route path="/user/payment">
                <UserPaymentPage />
            </Route>

            <Route path="/user/order">
                <OrderManagePage type="" />
            </Route>
            <Route path="/user/delivered-order">
                <OrderManagePage type="delivered" />
            </Route>

            <Route path="/user/shipped-order">
                <OrderManagePage type="shipped" />
            </Route>

            <Route path="/user/processing-order">
                <OrderManagePage type="processing" />
            </Route>

            <Route path="/user/pending-order">
                <OrderManagePage type="pending" />
            </Route>

            <Route path="/user/canceled-order">
                <OrderManagePage type="canceled" />
            </Route>

            <Route path="/user/prepared-order">
                <OrderManagePage type="prepared" />
            </Route>

            <Route path="/user/refunded-order">
                <OrderManagePage type="refunded" />
            </Route>

            <Route path="/user/order-detail/:invoiceId">
                <OrderDetailsPage />
            </Route>

            <Route path="/user/vouchers">
                <UserVoucherPage />
            </Route>

            <Route path="/user/wishlist">
                <UserWishlistPage />
            </Route>

            <Route path="/user/reviews">
                <UserReviewPage />
            </Route>

            <Route path="/user/logout">
                <UsersLogout />
            </Route>

            <Route path="/user">
                <UsersDashboard />
            </Route>
        </Switch>
    );
}
export default UserRouters;
