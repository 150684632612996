import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import UserMenu from '../comp/UserMenu';

function UserReviewPage() {
    PageSetProperties('Wishlist');
    const { initData } = InitInfo();

    const dispatch = useDispatch();
    const [show, setShow] = useState(!!initData.initId);
    const history = useHistory();
    const { invoiceId } = useParams();
    const isLogin = localStorage.getItem('Secure-Access');

    React.useEffect(() => {
        // Collect API Data
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                if (isLogin) {
                    setShow(initStatus);
                } else {
                    history.push('/registration');
                }
            })
            .catch((err) => {
                console.log(err);
            });
        // Collect invoice data
    }, [dispatch, history, invoiceId, isLogin]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu />

            <section className="user-section">
                <Container>
                    <div className="row">
                        <UserMenu />
                        <div className="col-12 col-lg-9">
                            <div className="order-section-detail">
                                <div className="order-section-detail-related-product" />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer />
        </Preview>
    );
}

export default UserReviewPage;
