import { combineReducers } from 'redux';
import BackdropCommonReducer from './reducers/BackdropCommonReducer';
import CartReducer from './reducers/CartReducer';
import CheckOtuReducer from './reducers/CheckOtuReducer';
import InitSetReducer from './reducers/InitSetReducer';
import IsLoggedInReducer from './reducers/IsLoggedInReducer';
import ProductDetailsReducer from './reducers/ProductDetailsReducer';
import ShopInitReducer from './reducers/ShopInitReducer';
import SnackbarCommonReducer from './reducers/SnackbarCommonReducer';
import UserAddressReducer from './reducers/UserAddressReducer';
import UserInformationReducer from './reducers/UserInformationReducer';

const rootReducer = combineReducers({
    activeSnackbar: SnackbarCommonReducer,
    activeBackdrop: BackdropCommonReducer,

    initData: InitSetReducer,
    shopData: ShopInitReducer,

    isLoggedIn: IsLoggedInReducer,
    userInformation: UserInformationReducer,
    userAddresses: UserAddressReducer,

    cartProducts: CartReducer,
    productDetails: ProductDetailsReducer,
    checkoutProducts: CheckOtuReducer,
    // recentVisitedSls: RecentVisitedProductReducer,
});

export default rootReducer;
