import ConfigApi from '../../configs/ConfigApi';
import AxiosAuth from '../utils/AxiosAuth';
import ObjIsEqual from '../utils/ObjIsEqual';

const initialState = [
    // {
    //     productId: 1000,
    //     quantity: 5
    // }
];

const creatingProductCart = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_CART_STATE_UPDATE, params)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function VariationInc(exVariationAr, selectedVariation, newQuantity) {
    const outputVariation = [...exVariationAr];
    let matched = false;
    for (let x = 0; x < exVariationAr.length; x += 1) {
        const variation = exVariationAr[x];

        if (
            ObjIsEqual(variation, {
                ...selectedVariation,
                quantity: variation.quantity,
                isSelected: variation.isSelected,
            })
        ) {
            matched = true;
            outputVariation[x].quantity =
                newQuantity === -1 ? 0 : newQuantity || variation.quantity + 1;
        }
    }

    if (matched === false) {
        outputVariation.push({
            // Default = 1
            ...selectedVariation,
            quantity: newQuantity || 1,
            isSelected: true,
        });
    }

    return outputVariation;
}

function VariationSelection(exVariationAr, selectedVariation, isSelected) {
    const outputVariation = [...exVariationAr];

    for (let x = 0; x < exVariationAr.length; x += 1) {
        const variation = exVariationAr[x];

        if (
            ObjIsEqual(variation, {
                ...selectedVariation,
                quantity: variation.quantity,
                isSelected: variation.isSelected,
            })
        ) {
            outputVariation[x].isSelected = isSelected;
        }
    }

    return outputVariation;
}
const CartReducer = (state = initialState, action) => {
    const shopId = process.env.REACT_APP_SHOP_ID;

    switch (action.type) {
        case 'CART_INIT_SET': {
            return action.payload;
        }

        case 'CART_ADD_PRODUCT': {
            const { productId, selectedVariation, newQuantity } = action.payload;
            const index = state.map((item) => item.productId).indexOf(productId);
            const newState = state;

            if (index >= 0) {
                // Ex Product
                const variationAr = VariationInc(
                    newState[index].productVariation,
                    selectedVariation,
                    newQuantity
                );
                console.log(variationAr);
                newState[index].productVariation = variationAr;
            } else {
                // New Product
                newState.push({
                    productId,
                    productVariation: [
                        // Default = 1
                        { ...selectedVariation, quantity: newQuantity || 1, isSelected: true },
                    ],
                });
            }

            creatingProductCart({
                shopId,
                newState,
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((err) => {
                    console.log(err);
                });
            return newState;
        }

        case 'CART_PRODUCT_SELECT': {
            const { productId, selectedVariation, isSelected } = action.payload;
            const index = state.map((item) => item.productId).indexOf(productId);
            const newState = state;

            if (index >= 0) {
                const variationAr = VariationSelection(
                    newState[index].productVariation,
                    selectedVariation,
                    isSelected
                );
                newState[index].productVariation = variationAr;
            }

            creatingProductCart({
                shopId,
                newState,
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((err) => {
                    console.log(err);
                });

            return newState;
        }

        case 'CART_CLEAR_PRODUCT': {
            const newState = [];

            creatingProductCart({
                shopId,
                newState,
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((err) => {
                    console.log(err);
                });

            return newState;
        }

        default:
            return state;
    }
};

export default CartReducer;
