import React from 'react';
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function UserOrders(props) {
    const {
        itemData: { imgUrl, title },
    } = props;
    return (
        <div className="row">
            <div
                className="col-12"
                style={{ borderBottom: '1px solid #e5dede', marginBottom: '10px' }}
            >
                <h5 className="user-orders-number">
                    Order <Link to="/order-detail">#610673182633936</Link>
                </h5>
                <h6 className="user-orders-time">Placed on 04 Nov 2020 16:17:53</h6>
            </div>
            <div className="col-4 col-lg-2">
                <div className="user-orders-img">
                    <img
                        src={`https://www.optimizee.xyz/images/${btoa(imgUrl)},200,300.jpg`}
                        alt=""
                    />
                </div>
            </div>
            <div className="col-8 col-lg-3 order-viewTitle">
                <p>{title}</p>
            </div>
            <div className="col-3 order-viewQty">Qty:1</div>
            <div className="col-2 order-viewStatus">
                <Badge className="btn xs btn-light">Delivered</Badge>
            </div>
            <div className="col-3 order-viewStatus">Others</div>
        </div>
    );
}
export default UserOrders;
