import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

export default function SearchOption() {
    const [hovered, setHovered] = useState(false);
    const [focused, setFocused] = useState(false);

    let searchClassName = 'search-box';
    if (hovered || focused) {
        searchClassName = 'search-box obj-focused';
    }

    return (
        <InputGroup
            className={searchClassName}
            onMouseEnter={() => {
                setHovered(true);
            }}
            onMouseLeave={() => {
                setHovered(false);
            }}
        >
            <FormControl
                className="search-form-control"
                placeholder="Search your fashion"
                onFocus={() => {
                    setFocused(true);
                }}
                onBlur={() => {
                    setFocused(false);
                }}
            />
            <InputGroup.Append>
                <button type="button" className="btn btn-danger btn-search">
                    <FontAwesomeIcon icon={faSearch} />
                </button>
            </InputGroup.Append>
        </InputGroup>
    );
}
