import React from 'react';
import { Link } from 'react-router-dom';
import StatusBtn from '../../../utils/StatusBtn2';

function UserOrdersSection({ memoData, memoItemData }) {
    return (
        <div className="row">
            <div
                className="col-6"
                style={{ borderBottom: '1px solid #e5dede', marginBottom: '10px' }}
            >
                <h5 className="user-order-section-number">
                    Order Id: #
                    <Link to={`/user/order-detail/${memoData.id}`}>INV {memoData.id}</Link>
                </h5>
                <h6 className="user-order-section-time">Placed on {memoData.time_created}</h6>
            </div>
            <div
                className="col-6"
                style={{ borderBottom: '1px solid #e5dede', marginBottom: '10px' }}
            >
                <Link
                    to={`/user/order-detail/${memoData.id}`}
                    className="ml-3 float-right user-order-section-btn-detail"
                >
                    Detail
                </Link>
                <button type="button" className="float-right user-order-section-btn-review">
                    Write a Review
                </button>
            </div>
            <div className="col-12">
                {memoItemData &&
                    memoItemData.map((item) => (
                        <div className="row bottom-border" key={item.id}>
                            <div className="col-4 col-lg-2">
                                <div className="user-orders-img">
                                    <img
                                        src={`https://www.optimizee.xyz/images/${btoa(
                                            item.product_image
                                        )},200,300.jpg`}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="col-8 col-lg-3">
                                <div className="order-section-detail-title">
                                    <Link to={item.url}>
                                        <b>{item.product_title}</b>
                                    </Link>
                                </div>

                                {/* <br />
                                <ul className="variant-list">
                                    <CartProductVariant
                                        data={Object.values(item.variation).slice(
                                            0,
                                            Object.keys(item.variation).length - 2
                                        )}
                                    />
                                </ul> */}
                            </div>
                            <div className="col-1 order-viewQty">x{item.quantity}</div>
                            <div className="col-2 order-viewStatus">
                                <StatusBtn status={memoData.status} />
                            </div>
                            <div className="col-4 order-viewStatus">
                                <div className="order-estimated-time">
                                    Estimated Time:
                                    <br /> Sat 25 Sep - Sun 26 Sep
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}
export default UserOrdersSection;
