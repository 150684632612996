import { faTshirt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

function IconWithTitle(title) {
    return (
        <>
            <FontAwesomeIcon icon={faTshirt} /> <b> {title}</b>
        </>
    );
}

function BulletWithTitle(title) {
    return (
        <>
            <span className="custom-bullet" /> <span> {title}</span>
        </>
    );
}

function BuildMenuUl({ SubMenuClass, MenuData }) {
    if (Array.isArray(MenuData) && MenuData.length) {
        return (
            <div className={SubMenuClass}>
                <ul>
                    {MenuData.map((item) => (
                        <BuildMenuLi item={item} key={item.id} />
                    ))}
                </ul>
            </div>
        );
    }

    // console.error('`MenuData` is not array', MenuData);
    return null;
}

function BuildMenuLi({ item: { title, icon, url, subMenu, subMenuClass } }) {
    const LiRef = useRef(null);

    let iconFunc = null;
    if (icon === 'IconWithTitle') {
        iconFunc = IconWithTitle;
    } else if (icon === 'BulletWithTitle') {
        iconFunc = BulletWithTitle;
    }

    useEffect(() => {
        const el = LiRef.current;
        const elDiv = el.querySelectorAll('div .sub-menu-l2 li');

        const mouseOver = () => {
            el.className = 'open';
        };
        const mouseOut = () => {
            el.className = '';
        };

        const clickFunction = () => {
            el.className = '';
        };

        el.addEventListener('mouseover', mouseOver, false);
        el.addEventListener('mouseout', mouseOut, false);

        if (elDiv) {
            for (let i = 0; i < elDiv.length; i += 1) {
                elDiv[i].addEventListener('click', clickFunction);
            }
        }

        return () => {
            el.removeEventListener('mouseover', mouseOver);
            el.removeEventListener('mouseout', mouseOut);

            if (elDiv) {
                for (let i = 0; i < elDiv.length; i += 1) {
                    elDiv[i].removeEventListener('click', clickFunction);
                }
            }
        };
    }, []);

    return (
        <li ref={LiRef}>
            <Link
                to={url}
                onClick={(e) => {
                    if (subMenu?.length) {
                        e.preventDefault();
                    }
                }}
            >
                <span>{typeof iconFunc === 'function' ? iconFunc(title) : title}</span>
            </Link>
            {subMenu && <BuildMenuUl MenuData={subMenu} SubMenuClass={subMenuClass} />}
        </li>
    );
}

function DataGroup(array) {
    const output = {};

    array.forEach((el) => {
        if (!output[el.parent_id]) {
            output[el.parent_id] = {};
        }

        output[el.parent_id][el.id] = el;
    });

    return output;
}

function DataTree(groupedObject, id, label) {
    const output = [];

    if (groupedObject[id]) {
        const keys = Object.keys(groupedObject[id]);
        keys.forEach((key) => {
            output.push({
                ...groupedObject[id][key],

                subMenuClass: `sub-menu-l${label}`,
                subMenu: DataTree(groupedObject, groupedObject[id][key].id, label + 1),
            });
        });
    }

    return output;
}

export default function MenuPrimary({ MenuData = {} }) {
    const groupedData = DataGroup(MenuData);
    const TreedArray = DataTree(groupedData, '0', 1);

    return <BuildMenuUl MenuData={TreedArray} SubMenuClass="fashion-menu primary-menu" />;
}
