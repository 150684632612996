import React from 'react';
import { useLocation } from 'react-router-dom';
import StepHandler from '../../../utils/StepHandler';
import UserAddressCreateModal from '../modals/UserAddressCreateModal';

export default function AddressAddModal({ exiturl }) {
    const location = useLocation();
    return (
        <StepHandler pathname={location.pathname} exiturl={exiturl}>
            <UserAddressCreateModal path="/user/address/create/" />
            <div path="/user/address/create/demo" />
        </StepHandler>
    );
}
