import React from 'react';

export default function StatusBtn2({ status }) {
    let bgColor = '#dd6969';
    let tColor = 'tColor';
    if (status === 'processing') {
        bgColor = 'rgb(191 66 66 / 13%)';
        tColor = 'rgb(230 96 248 / 98%)';
    }
    if (status === 'pending') {
        bgColor = '#fcfc2452';
        tColor = 'rgb(193 188 7)';
    }
    if (status === 'prepared') {
        bgColor = 'rgba(86, 127, 219, 0.2)';
        tColor = 'rgb(86, 127, 219)';
    }
    if (status === 'delivered') {
        bgColor = 'rgba(85, 178, 99, 0.2)';
        tColor = 'rgb(85, 178, 99)';
    }
    if (status === 'shipped') {
        bgColor = '#00edff42';
        tColor = 'rgb(19 205 201)';
    }
    if (status === 'canceled') {
        bgColor = 'rgba(232, 60, 80, 0.2)';
        tColor = 'rgb(232, 60, 80)';
    }
    if (status === 'refunded') {
        bgColor = 'rgb(0 150 136 / 38%)';
        tColor = 'rgb(27 149 138 / 97%)';
    }
    return (
        <button
            type="button"
            style={{ color: tColor, background: bgColor, fontSize: '10px' }}
            className="order-section-status-btn"
        >
            {status.toUpperCase()}
        </button>
    );
}
