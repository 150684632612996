/* eslint-disable no-useless-escape */
export default function ValidateEmailPattern(mail) {
    const found = mail.match(
        /([a-z0-9]{1})([a-z0-9\.\-\_]+)(@)([a-z0-9\-\.]+)(.)([a-z0-9]{2,8})/gim
    );

    if (found !== null && found[0] === mail) {
        return true;
    }

    return false;
}
