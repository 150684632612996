import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function FlashSaleProduct({ itemData, discount }) {
    return (
        <Card>
            <Link to={itemData.url}>
                <Card.Img
                    className="flash-product-section-imgBox"
                    src={`https://www.optimizee.xyz/images/${btoa(
                        itemData.default_image
                    )},150,200.jpg`}
                />
            </Link>

            <Card.Body>
                <Card.Text>
                    <Link to={itemData.url}>{itemData.title}</Link>
                </Card.Text>

                <div className="pricing">
                    <strong>৳ {itemData.total_price}</strong>
                    <br />
                    <span className="main-price">৳ 2000</span>
                    <span className="offer">-{discount}% off</span>
                    {/* <div className="sticker-discount">
                        <p>Off</p>
                    </div> */}
                </div>
            </Card.Body>
        </Card>
    );
}
