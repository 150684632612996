/* eslint-disable react/jsx-no-duplicate-props */
import { faCaretRight, faList, faTh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import { useDispatch, useSelector } from 'react-redux';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import ProductPageDataMulti from '../comp/ProductPageDataMulti';
import ProductPageSingle from '../comp/ProductPageSingle';

const Colors = [
    {
        id: 1,
        color: 'info',
    },
    {
        id: 2,
        color: 'primary',
    },
    {
        id: 3,
        color: 'danger',
    },
    {
        id: 4,
        color: 'secondary',
    },
    {
        id: 5,
        color: 'info',
    },
    {
        id: 6,
        color: 'primary',
    },
    {
        id: 7,
        color: 'danger',
    },
    {
        id: 8,
        color: 'secondary',
    },
];

function ProductColor(props) {
    const { color } = props;
    return <Button variant={color.color} />;
}
export default function ProductPage() {
    PageSetProperties('Products');
    const dispatch = useDispatch();
    const initData = useSelector((state) => state.initData);
    const [show, setShow] = useState(!!initData.initId);
    const shopData = useSelector((state) => state.shopData);
    const productData = shopData.products;

    const [myClass, setMyClass] = useState('product-section-colI3');

    const handleColumThree = () => {
        setMyClass('product-section-colI3');
    };
    const handleColumOne = () => {
        setMyClass('product-section-colI1');
    };

    useEffect(() => {
        // effect

        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu shopData={shopData} />
            <section className="PageSection">
                <Container>
                    <div className="page-banner" />

                    <div className="row">
                        <div className="d-none d-lg-block d-xl-block col-lg-3 col-lg-3">
                            <div className="page-brand">
                                <h6>Brand</h6>
                                <ul>
                                    <li>
                                        <label htmlFor="Top Ten">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Top Ten</span>
                                    </li>
                                    <li>
                                        <label htmlFor="Top Star">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Top Star</span>
                                    </li>
                                    <li>
                                        <label htmlFor="Arrong">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Arrong</span>
                                    </li>
                                    <li>
                                        <label htmlFor="Sara">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Sara</span>
                                    </li>
                                    <li>
                                        <label htmlFor="Raymond">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Raymond</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="page-size">
                                <h6>Size</h6>
                                <ul>
                                    <li>
                                        <label htmlFor="Top Ten">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Top Ten</span>
                                    </li>
                                    <li>
                                        <label htmlFor="Top Star">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Top Star</span>
                                    </li>
                                    <li>
                                        <label htmlFor="Arrong">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Arrong</span>
                                    </li>
                                    <li>
                                        <label htmlFor="Sara">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Sara</span>
                                    </li>
                                    <li>
                                        <label htmlFor="Raymond">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Raymond</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="page-service">
                                <h6>Services</h6>
                                <ul>
                                    <li>
                                        <label htmlFor="Top Ten">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Top Ten</span>
                                    </li>
                                    <li>
                                        <label htmlFor="Top Star">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Top Star</span>
                                    </li>
                                    <li>
                                        <label htmlFor="Arrong">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Arrong</span>
                                    </li>
                                    <li>
                                        <label htmlFor="Sara">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Sara</span>
                                    </li>
                                    <li>
                                        <label htmlFor="Raymond">
                                            <input type="checkbox" />
                                        </label>
                                        <span>Raymond</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="PagePrice">
                                <h6>Price</h6>
                                <input placeholder="Min Price" />
                                <input placeholder="Max Price" />
                                <Button className="btn-sm btn-danger">
                                    <FontAwesomeIcon icon={faCaretRight} />
                                </Button>
                            </div>
                            <div className="PageColor">
                                <h6>Color</h6>
                                {Colors.map((color) => (
                                    <ProductColor color={color} key={color.id} />
                                ))}
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-9 col-lg-9">
                            <div className="row row-m-product">
                                <div className="col-12 col-m-product">
                                    <div className="page-filter-view">
                                        <div className="pageFilter">
                                            <div className="page-filter-option">
                                                <div className=" btn-group">
                                                    <Button
                                                        size="sm"
                                                        active={myClass === 'product-section-colI1'}
                                                        variant="outline-danger"
                                                        onClick={handleColumOne}
                                                    >
                                                        <FontAwesomeIcon icon={faList} />
                                                    </Button>
                                                    <Button
                                                        size="sm "
                                                        active={myClass === 'product-section-colI3'}
                                                        variant="outline-danger"
                                                        onClick={handleColumThree}
                                                    >
                                                        <FontAwesomeIcon icon={faTh} />
                                                    </Button>
                                                </div>
                                                <div className="btn-group float-right">
                                                    <Button size="sm " variant="outline-danger">
                                                        Best Sellers
                                                    </Button>
                                                    <Button size="sm " variant="outline-danger">
                                                        Newest
                                                    </Button>
                                                    <Button size="sm " variant="outline-danger">
                                                        Top Rated
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {productData &&
                                    productData.map((item) => (
                                        <div
                                            key={item.id}
                                            className={[
                                                'col mb-4 col-m-product',
                                                myClass === 'product-section-colI1'
                                                    ? 'product-section-colI1'
                                                    : 'product-section-colI3',
                                            ].join(' ')}
                                        >
                                            {myClass === 'product-section-colI1' ? (
                                                <LazyLoad throttle={200} height={300}>
                                                    <ProductPageSingle itemData={item} />
                                                </LazyLoad>
                                            ) : (
                                                <LazyLoad throttle={200} height={300}>
                                                    <ProductPageDataMulti itemData={item} />
                                                </LazyLoad>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer />
        </Preview>
    );
}
