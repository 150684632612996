/* eslint-disable no-unused-vars */
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import shoppingImage from '../../../assets/images/shopping.jpg';
import ConfigApi from '../../../configs/ConfigApi';
import AppInitDispatch from '../../dispatches/AppInitDispatch';
import AxiosAuth from '../../utils/AxiosAuth';
import PageSetProperties from '../../utils/PageSetProperties';
import Preview from '../../utils/Preview';
import TimerCount from '../../utils/TimerCount';
import UtilAuth from '../../utils/UtilAuth';
import Footer from '../system/section/FooterSection';
import HeaderSection from '../system/section/HeaderSection';

const countryCode = '880';
const resendDelaySec = 60;
const shopSl = process.env.REACT_APP_SHOP_ID;

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '50px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
// --Functions
const passwordLoginVerify = (data, callback) => {
    AxiosAuth.post(ConfigApi.API_LOGIN_PHONE_BY_PASS, data)
        .then((response) => {
            if (callback) {
                callback(response);
            }

            // --Adding Secure Token
            if (response.headers['secure-access']) {
                localStorage.setItem('Secure-Access', response.headers['secure-access']);
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

// --Functions
const PhoneForm = ({ classes, handleChange, value, handleSubmit }) => (
    <form className={classes.form} noValidate>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Mobile Number"
            name="text"
            autoComplete="text"
            autoFocus
            onChange={handleChange}
            value={value}
            className={[classes.submit].join(' ')}
        />
        <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            className={[classes.submit].join(' ')}
        >
            Next
        </Button>
    </form>
);

const PhoneOtpForm = ({
    classes,
    handleChange,
    value,
    handleChangeOtp,
    valueOtp,
    handleSubmit,
    dispatch,
}) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [countResend, setCountResend] = useState(0);

    const handleChangeSecond = (remainSec) => {
        if (remainSec <= 0) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    };

    const handleResend = (ev) => {
        setIsDisabled(true);
        UtilAuth.phoneLoginOtpSend(countryCode, value, shopSl, dispatch).then(() => {
            setCountResend(countResend + 1);
        });
    };

    return (
        <form className={classes.form} noValidate>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Mobile Number"
                name="text"
                autoComplete="text"
                onChange={handleChange}
                value={value}
                className={[classes.submit, 'disabled-link'].join(' ')}
            />

            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="OTP"
                name="text"
                autoComplete="text"
                onChange={handleChangeOtp}
                value={valueOtp}
                autoFocus
            />

            <span>
                <Chip
                    color="primary"
                    size="small"
                    disabled={isDisabled}
                    label="Resend"
                    onClick={handleResend}
                />{' '}
                {isDisabled ? (
                    <>
                        OTP in{' '}
                        <TimerCount
                            second={resendDelaySec}
                            key={countResend}
                            handleChange={handleChangeSecond}
                            style={{ marginLeft: '10px' }}
                        />{' '}
                        second
                    </>
                ) : null}
            </span>

            <br />

            <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                className={[classes.submit].join(' ')}
            >
                Login
            </Button>
        </form>
    );
};

const PhonePassForm = ({
    classes,
    handleChange,
    value,
    handleChangeOtp,
    valueOtp,
    handleSubmit,
}) => (
    <form className={classes.form} noValidate>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="mobile"
            label="Mobile Number"
            name="text"
            autoComplete="text"
            onChange={handleChange}
            value={value}
            className={[classes.submit, 'disabled-link'].join(' ')}
        />

        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            name="text"
            autoComplete="text"
            onChange={handleChangeOtp}
            value={valueOtp}
            autoFocus
            type="password"
        />

        <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            className={[classes.submit].join(' ')}
        >
            Login
        </Button>
    </form>
);
function RegistrationPage() {
    PageSetProperties('Registration');

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const initData = useSelector((state) => state.initData);
    const [show, setShow] = useState(!!initData.initId);

    const [formType, setFormType] = useState('phone');
    const [userVal, setUserVal] = useState('');
    const [authVal, setAuthVal] = useState('');
    const [timeCounter] = useState('');

    // Forms
    // 1. undefined
    // 2. phone
    // 3. phone_otp
    // 4. phone_password
    // 5. email
    // 6. email_otp
    // 7. email_password

    let targetUrl = '/';

    if (history.location.search === '?profile') {
        targetUrl = '/user/profile';
    }
    if (history.location.search === '?cart') {
        targetUrl = '/cart';
    }
    if (history.location.search === '?checkout') {
        targetUrl = '/checkout';
    }
    if (history.location.search === '?address') {
        targetUrl = '/user/address';
    }

    const handleChange = (ev) => {
        setUserVal(ev.target.value);
    };

    const handleChangeOtp = (ev) => {
        setAuthVal(ev.target.value);
    };

    const handleSubmit = () => {
        if (formType === 'phone') {
            UtilAuth.phoneLogin(countryCode, userVal, shopSl, dispatch).then((response) => {
                if (response.data.error === 0) {
                    if (response.data.havingPassword) {
                        setFormType('phone_password');
                    } else {
                        UtilAuth.phoneLoginOtpSend(countryCode, userVal, shopSl, dispatch).then(
                            () => {
                                setFormType('phone_otp');
                            }
                        );
                    }
                }
            });
        } else if (formType === 'phone_otp') {
            UtilAuth.phoneLoginByOtp(countryCode, userVal, shopSl, authVal, dispatch).then(
                (response) => {
                    if (response.data.error === 0) {
                        localStorage.setItem('Secure-Access', response.headers['secure-access']);
                        AppInitDispatch(dispatch)
                            .then((initStatus) => {
                                console.log(initStatus);
                                history.push(targetUrl);
                                // Collect Recent Orders
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                }
            );
        } else if (formType === 'phone_password') {
            UtilAuth.phoneLoginByPass(countryCode, userVal, shopSl, authVal, dispatch).then(
                (response) => {
                    if (response.data.error === 0) {
                        localStorage.setItem('Secure-Access', response.headers['secure-access']);

                        AppInitDispatch(dispatch, true)
                            .then((initStatus) => {
                                console.log(initStatus);
                                history.push(targetUrl);
                                // Collect Recent Orders
                            })
                            .catch((err) => {
                                console.log(err);
                            });

                        history.push(targetUrl);
                    }
                }
            );
        }
    };

    let Form = null;
    if (formType === 'phone') {
        Form = (
            <PhoneForm
                classes={classes}
                handleChange={handleChange}
                value={userVal}
                handleSubmit={handleSubmit}
            />
        );
    } else if (formType === 'phone_otp') {
        Form = (
            <PhoneOtpForm
                classes={classes}
                handleChange={handleChange}
                value={userVal}
                handleChangeOtp={handleChangeOtp}
                valueOtp={authVal}
                handleSubmit={handleSubmit}
                timeCounter={timeCounter}
                dispatch={dispatch}
            />
        );
    } else if (formType === 'phone_password') {
        Form = (
            <PhonePassForm
                classes={classes}
                handleChange={handleChange}
                value={userVal}
                handleChangeOtp={handleChangeOtp}
                valueOtp={authVal}
                handleSubmit={handleSubmit}
            />
        );
    }
    useEffect(() => {
        // effect

        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu />
            <section className="PageSection">
                <Container>
                    <div className="row" style={{ marginTop: '20px' }}>
                        <div className="col-12 col-lg-6">
                            <div className="reg-content reg-content-section">
                                <div
                                    className={['reg-content-section-input', classes.paper].join(
                                        ' '
                                    )}
                                >
                                    <Avatar className={classes.avatar}>
                                        <LockOpenIcon />
                                    </Avatar>
                                    <Typography component="h3" variant="h6">
                                        Register Now
                                    </Typography>

                                    {Form}
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-block col-lg-6">
                            <div className="reg-content">
                                <div className="reg-page-img">
                                    <img src={shoppingImage} alt="Shopping" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer />
        </Preview>
    );
}
export default RegistrationPage;
