/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Card } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Link } from 'react-router-dom';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';
import Optimizee from '../../../utils/Optimizee';
import StatusBtn from '../../../utils/StatusBtn';
import CartProductVariant from '../comp/CartProductVariant';

const useStyles = makeStyles(() => ({
    table: {
        minWidth: 650,
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
    },
    tableContainer: {
        overflow: 'hidden',
        padding: '10px',
        position: 'relative',
        zIndex: 0,
        boxShadow: 'none',
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
}));
function TableData({ rowData, qty, variation }) {
    const classes = useStyles();
    return (
        <TableRow key={rowData.id} className={[classes.tableRowHover, 'tableTr '].join(' ')}>
            <TableCell style={{ width: '96px' }}>
                <img
                    className={classes.image}
                    src={Optimizee(rowData.default_image, 100)}
                    alt={rowData.title}
                />
            </TableCell>
            <TableCell align="left">
                <b> {rowData.title}</b>
                <br />
                <ul className="variant-list">
                    <CartProductVariant
                        data={Object.values(variation).slice(0, Object.keys(variation).length - 2)}
                    />
                </ul>
            </TableCell>
            <TableCell align="center">{qty}</TableCell>
            <TableCell align="center">{rowData.total_price.toFixed(2)}</TableCell>
            <TableCell align="center" width="10">
                {(rowData.total_price * qty).toFixed(2)}
            </TableCell>
        </TableRow>
    );
}
export default function InvoiceSection({
    productData,
    invoiceData,
    invoiceItemData,
    allInvoice,
    address,
}) {
    const classes = useStyles();
    const { shopData } = InitInfo();
    let paymentStatus = '';
    if (invoiceData.net_amount <= invoiceData.paidAmount && invoiceData.paidAmount !== 0) {
        paymentStatus = 'Paid';
    } else if (invoiceData.net_amount > invoiceData.paidAmount && invoiceData.paidAmount !== 0) {
        paymentStatus = 'Partial Paid';
    } else {
        paymentStatus = 'Not Paid';
    }
    const FindArr = new FindInArray();
    FindArr.init(productData, 'id');
    let totalAmount = 0;
    return (
        <section className="cart-section">
            <div className="container">
                <div className="form-row">
                    <div className="col-12 col-lg-3 col-md-3">
                        <Card>
                            <div className="col-12 invoice-head">
                                <h6>ORDERS</h6>
                            </div>

                            {allInvoice.map((invoice) => (
                                <Link
                                    to={`/invoice/${invoice.id}`}
                                    className="invoice-list-link"
                                    key={invoice.id}
                                >
                                    <div className="invoice-list">
                                        <div className="invoice-list-detail">
                                            <div className="invoice-list-detail-time">
                                                {invoice.time_created}
                                            </div>
                                            <div className="invoice-list-order-no">
                                                <b>INV {invoice.id}</b>
                                            </div>
                                        </div>
                                        <div className="invoice-list-status">
                                            <StatusBtn status={invoice.status} />
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </Card>
                    </div>
                    <div className="col-12 col-lg-9 col-md-9">
                        <Card>
                            <div className="row" style={{ padding: '10px' }}>
                                <div className="col-12 invoice-head">
                                    <h3>INVOICE</h3>
                                </div>
                                <div className="col-6">
                                    <div className="invoice-print-section">
                                        <span className="invoice-print-btn">
                                            <Link
                                                target="blank"
                                                to={`/invoice/${invoiceData.id}/print`}
                                            >
                                                Print
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="order-detail-section-order-no">
                                        <span>{paymentStatus}</span>
                                        <br />
                                        <h6>INV-{invoiceData.id}</h6>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="order-detail-section-title">
                                        <h3>INVOICE FROM</h3>
                                        <p>{shopData.shopInfo.shop_name}</p>
                                        <p style={{ lineHeight: '14px' }}>
                                            {shopData.shopInfo.address}
                                        </p>
                                        <p>{shopData.shopInfo.contact_number}</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="order-detail-section-title">
                                        <h3>INVOICE TO</h3>
                                        <p>{address.name}</p>
                                        <p>{address.area}</p>
                                        <p>{address.city}</p>
                                        <p>{address.district}</p>
                                        <p>{address.phone}</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <TableContainer
                                        className={classes.tableContainer}
                                        component={Paper}
                                    >
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow className="tableTr">
                                                    <TableCell>Description</TableCell>
                                                    <TableCell />
                                                    <TableCell align="center">Qty</TableCell>
                                                    <TableCell align="center">Unit Price</TableCell>
                                                    <TableCell align="center">Total</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {invoiceItemData.map((row) => {
                                                    totalAmount += row.price * row.quantity;
                                                    const itemData = FindArr.find(row.productId);
                                                    return (
                                                        <TableData
                                                            qty={row.quantity}
                                                            key={`${row.productId}-${itemData.title}`}
                                                            rowData={itemData}
                                                            variation={row.product_variation_json}
                                                        />
                                                    );
                                                })}
                                            </TableBody>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan="4" align="right">
                                                        Subtotal
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {totalAmount.toFixed(2)}
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell colSpan="4" align="right">
                                                        Shipping
                                                    </TableCell>
                                                    <TableCell align="center">50.00</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan="4" align="right">
                                                        Total
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {(totalAmount + 50).toFixed(2)}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    );
}
