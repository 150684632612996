/* eslint-disable no-unused-vars */
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ThemeButton from '../../../components/basic/ThemeButton';

function ProductSize({ size, variants, itemName, handleToggleSub }) {
    const checked = variants[itemName] === size;
    return (
        <Button
            variant={checked ? 'primary' : 'outline-info'}
            className={['product-detail-sizeBtn', checked ? 'checked' : ''].join(' ')}
            onClick={handleToggleSub(itemName, size)}
        >
            {size}
        </Button>
    );
}

function ProductAgeRange({ age, variants, itemName, handleToggleSub }) {
    const checked = variants[itemName] === age;
    return (
        <Button
            size="sm"
            variant={checked ? 'primary' : 'outline-info'}
            className={['product-detail-ageBtn mr-2', checked ? 'checked' : ''].join(' ')}
            onClick={handleToggleSub(itemName, age)}
        >
            {age}
        </Button>
    );
}

function ProductColor({ color, variants, itemName, handleToggleSub }) {
    const checked = variants[itemName] === color;

    return (
        <div className={['color-item', checked ? 'checked' : ''].join(' ')} style={{ margin: 0 }}>
            <button
                type="button"
                style={{ backgroundColor: color }}
                onClick={handleToggleSub(itemName, color)}
            />
        </div>
    );
}

function ProductVariantOption({ itemName, itemData, variants, handleToggleSub }) {
    if (itemName === 'color') {
        return (
            <div className="product-detail-color">
                <h6 className="product-detail-size-title">Color:</h6>
                {itemData?.map((item) => (
                    <ProductColor
                        color={item}
                        key={item}
                        itemName={itemName}
                        variants={variants}
                        handleToggleSub={handleToggleSub}
                    />
                ))}
            </div>
        );
    }

    if (itemName === 'size') {
        return (
            <div className="product-detail-size">
                <h6 className="product-detail-size-title">Size:</h6>
                {itemData?.map((item) => (
                    <ProductSize
                        size={item}
                        key={item}
                        itemName={itemName}
                        variants={variants}
                        handleToggleSub={handleToggleSub}
                    />
                ))}
            </div>
        );
    }

    return (
        <div className="product-detail-age">
            <h6 className="product-detail-size-title">Age:</h6>
            {itemData?.map((item) => (
                <ProductAgeRange
                    age={item}
                    key={item}
                    itemName={itemName}
                    variants={variants}
                    handleToggleSub={handleToggleSub}
                />
            ))}
        </div>
    );
}

function ProductVariant({ data, variants, handleToggleSub }) {
    if (data) {
        return Object.keys(data).map((item) => (
            <div key={item}>
                <ProductVariantOption
                    handleToggleSub={handleToggleSub}
                    itemName={item}
                    itemData={data[item]}
                    variants={variants}
                />
            </div>
        ));
    }

    return null;
}
function QtyOption({ handleChange, handleQtyMinus, handleQtyPlus, newQuantity }) {
    return (
        <>
            <FontAwesomeIcon onClick={handleQtyMinus} size="sm" icon={faMinus} />
            <input
                style={{ border: 'none', width: '50px', textAlign: 'center' }}
                onChange={handleChange}
                value={newQuantity}
            />
            <FontAwesomeIcon onClick={handleQtyPlus} size="sm" icon={faPlus} />
        </>
    );
}
export default function ProdutctVariantModal({ productData, modalshow, handleModalClose }) {
    const [variants, setVariants] = useState({});
    const [newQuantity, setNewQuantity] = useState(1);
    const handleToggleSub = (key, value) => () => {
        setVariants({ ...variants, [key]: value });
        setNewQuantity(1);
    };

    const handleQtyPlus = () => {
        setNewQuantity(newQuantity + 1);
    };
    const handleQtyMinus = () => {
        if (newQuantity <= 1) {
            setNewQuantity(1);
        } else {
            setNewQuantity(newQuantity - 1);
        }
    };

    const handleChange = (e) => {
        setNewQuantity(e.target.value);
    };
    return (
        <Modal show={modalshow} onHide={handleModalClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="row">
                        <div className="col-3">
                            <img
                                src={`https://www.optimizee.xyz/images/${btoa(
                                    productData.default_image
                                )}.jpg`}
                                alt="Images"
                                height="50px"
                                width="50px"
                                style={{ marginTop: 10 }}
                            />
                        </div>
                        <div className="col-9">
                            <small style={{ fontSize: 15 }}>{productData.title}</small>
                            <br />
                            <small style={{ fontSize: 13 }}>
                                Price:{productData.total_price?.toFixed(2)}
                            </small>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProductVariant
                    handleToggleSub={handleToggleSub}
                    variants={variants}
                    data={productData?.variation_header || {}}
                />
                <div>
                    <div className="product-detail-color">
                        <h6 className="product-detail-size-title">Qty:</h6>
                        <QtyOption
                            handleChange={handleChange}
                            handleQtyMinus={handleQtyMinus}
                            handleQtyPlus={handleQtyPlus}
                            newQuantity={newQuantity}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ThemeButton
                    myClass="product-detail-optionBtn"
                    size="xs"
                    title="BUY NOW"
                    id={productData.id}
                    selectedVariation={variants}
                    isDisable={
                        Object.keys(productData?.variation_header || []).length !==
                        Object.keys(variants).length
                    }
                    shopId={productData.shopSl}
                    handleModalClose={handleModalClose}
                    newQuantity={newQuantity}
                    successMessage="Add to Cart"
                />
            </Modal.Footer>
        </Modal>
    );
}
