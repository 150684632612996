import React, { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import UserMenu from '../comp/UserMenu';

function UserPaymentPage() {
    PageSetProperties('Payment');

    const dispatch = useDispatch();
    const history = useHistory();

    const { shopData, show } = InitInfo();

    // const { initData, userInformation } = store.getState();
    const setShow = useState()[1];

    useEffect(() => {
        // Collect API Data
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, history, setShow]);
    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu shopData={shopData} />

            <section className="user-section">
                <Container>
                    <div className="row">
                        <UserMenu />
                        <div className="col-9">
                            <div className="user-title">
                                <h6>My Payment Options</h6>
                            </div>
                            <Card>
                                <Card.Body style={{ height: '100px' }} />
                            </Card>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer />
        </Preview>
    );
}
export default UserPaymentPage;
