/* eslint-disable react/jsx-props-no-spreading */
import { TextField, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import SnackbarOpenDispatch from '../../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { UserModalFooter, UserModalHeader } from './comp/UserModalHeaderFooter';

const updateUserContact = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_USER_ADD_FAMILY, params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};
const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};
export default function FamilyAddModal(props) {
    const { prev, next, step, paths, exiturl } = props;

    const theme = useTheme();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const { shopId } = InitInfo();
    const [name, setName] = useState('');
    const [birthDate, setBirthDate] = useState('2021-06-17');

    const [contact, setContact] = useState('');
    const history = useHistory();
    const dispatch = useDispatch();

    const handleValue = (event) => {
        if (event.target.name === 'name') {
            setName(event.target.value);
        }
        if (event.target.name === 'contact') {
            setContact(event.target.value);
        }
        if (event.target.name === 'birthDate') {
            setBirthDate(event.target.value);
        }
    };

    const handleNextClick = () => {
        // console.dir(next);
        updateUserContact({ name, birthDate, contact, shopId }, (response) => {
            if (response.data.error === 0) {
                handelSnackbarOpen(dispatch, response.data.message);
                // --Update Store
                history.push(exiturl);
            }
        });
    };

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <UserModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title="Add Family Member"
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Add New Family Member
                        </Typography>
                        <TextField
                            required
                            label="name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter name"
                            value={name}
                            name="name"
                            onChange={handleValue}
                        />
                        <TextField
                            required
                            label="Contact"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter Contact Number"
                            value={contact}
                            name="contact"
                            onChange={handleValue}
                            style={{ marginTop: 15 }}
                        />
                        <TextField
                            required
                            label="Birth Date"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please select date of birth"
                            value={birthDate}
                            name="birthDate"
                            onChange={handleValue}
                            style={{ marginTop: 15 }}
                            type="date"
                        />
                    </div>
                    <UserModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={name}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
