import React from 'react';

export default function ProductOverviewImages({ thumbImg, thumbImages, handleThumbImage }) {
    return (
        <>
            <div className="product-detail-imgBox">
                <div className="squire-image">
                    <div>
                        <img src={thumbImg} alt="" width="300" />
                    </div>
                </div>
            </div>
            <div className="product-detail-imgThumb">
                <div className="wrapper">
                    <div className="gallery">
                        <ul>
                            {thumbImages.map((imgUrl) => (
                                <li key={imgUrl.id}>
                                    <img
                                        onClick={() => handleThumbImage(imgUrl.url)}
                                        src={imgUrl.url}
                                        alt=""
                                        role="presentation"
                                        width="50px"
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
