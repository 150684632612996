/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import InitInfo from '../../../utils/InitInfo';
import {
    loggingChkMail,
    loggingChkMailOtp,
    loggingChkMailPassword,
    loggingDetectOption,
    // eslint-disable-next-line prettier/prettier
    loggingIn
} from '../models/UserAuthLogin';
import UserOtpComp from './UserOtpComp';

let dispatch = null;
const message = {
    101: 'We found you.',
    102: "We Didn't found you. Please create an account.",
    103: 'Please enter your password.',
    104: "Password didn't Match.",
    105: 'Please enter OTP',
    106: 'We sent OTP Code to your Email',
    107: 'We sent OTP Code to your Phone',
    108: "OTP Didn't Match",
};

// const onValidateUserType = (type) => {
//     console.log(type);
// };
const onValidationFailed = () => {};

const onMailOtp = () => {};
const onMailPassword = () => {};

const onPhoneOtp = () => {};
const onPhonePassword = () => {};

const onNewAccount = () => {};
const onNetworkError = () => {};

const onSendingSuccess = () => {};
const onSendingFailed = () => {};
const onMultipleTry = () => {};

const onOtpVerified = () => {};
const onOtpUnverified = () => {};

const onPassVerified = (isSuccess, secretKey, setLoginStatus) => {
    if (isSuccess) {
        loggingIn(dispatch, secretKey, () => {
            setLoginStatus(true);
        });
    }
};
const onPassUnverified = () => {};

const onExitButtonClick = () => {};

//--

const BtnEmailPasswordNext = ({
    username,
    usernameType,
    setAccountType,
    setVerificationMethod,
    setOtpKey,
    btnName,
    setBtnName,
}) => {
    console.log(1);

    if (btnName !== 'BtnEmailPasswordNext') {
        return null;
    }

    const onSuccess = (response) => {
        setAccountType(response.data.type); // found/new
        setVerificationMethod(response.data.method); // password/otp
        setOtpKey(Math.round(Math.random() * 10000000));

        if (response.data.method === 'password') {
            setBtnName('BtnEmailPasswordLogin');
        }
    };

    const onError = (response) => {
        console.log(response);
    };

    return (
        <button
            type="button"
            className="classic-btn"
            onClick={(params) => {
                if (usernameType === 'email') {
                    loggingChkMail(username, onSuccess, onError);
                }
            }}
        >
            <img src={require('../../../../assets/images/btn-next-sm.svg').default} alt="" />
        </button>
    );
};

const BtnEmailPasswordLogin = ({
    username,
    password,
    usernameType,
    setLoginStatus,
    setAccountType,
    setVerificationMethod,
    btnName,
}) => {
    if (btnName !== 'BtnEmailPasswordLogin') {
        return null;
    }

    return (
        <button
            type="button"
            className="classic-btn"
            onClick={(params) => {
                if (usernameType === 'email') {
                    loggingChkMailPassword(
                        username,
                        password,
                        setLoginStatus,
                        onPassVerified,
                        onMultipleTry,
                        onNetworkError
                    );
                }
            }}
        >
            <img src={require('../../../../assets/images/btn-login-sm.svg').default} alt="" />
        </button>
    );
};

const BtnEmailOtpLogin = ({
    username,
    otp,
    usernameType,
    setLoginStatus,
    setAccountType,
    setVerificationMethod,
    btnName,
}) => {
    if (btnName !== 'BtnEmailOtpLogin') {
        return null;
    }

    return (
        <button
            type="button"
            className="classic-btn"
            onClick={(params) => {
                if (usernameType === 'email') {
                    loggingChkMailOtp(
                        username,
                        otp,
                        setLoginStatus,
                        onPassVerified,
                        onMultipleTry,
                        onNetworkError
                    );
                }
            }}
        >
            <img src={require('../../../../assets/images/btn-login-sm.svg').default} alt="" />
        </button>
    );
};

// --Sub Comp

function UserLoginComps() {
    dispatch = useDispatch();
    const { isLoggedIn } = InitInfo();

    const [usernameType, setUsernameType] = useState(null); // phone/email/username
    const [accountType, setAccountType] = useState(null); // found/new
    const [verificationMethod, setVerificationMethod] = useState(null); // password/otp

    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [otp, setOtp] = useState(null);
    const [otpKey, setOtpKey] = useState('N/A');
    const [loginStatus, setLoginStatus] = useState(isLoggedIn);
    const [btnName, setBtnName] = useState('BtnEmailPasswordNext');

    let title = 'Log in / Registration';
    if (accountType === 'found') {
        title = 'Login to Account';
    } else if (accountType === 'new') {
        title = 'Create new Account';
    }

    let labelUsername = 'Username/Email/Phone Number';
    if (usernameType === 'username') {
        labelUsername = 'Username';
    } else if (usernameType === 'email') {
        labelUsername = 'Email';
    } else if (usernameType === 'phone') {
        labelUsername = 'Phone';
    }

    const onResendClick = () => {
        //--
        if (usernameType === 'email') {
            loggingChkMail(
                username,
                setAccountType,
                setVerificationMethod,
                setOtpKey,
                onNetworkError
            );
            setOtp('');
        }
    };

    if (loginStatus) {
        return null;
    }

    return (
        <div className="modal-classic">
            <div className="classic-main">
                <div className="classic-header">
                    <button type="button" className="classic-exit" onClick={onExitButtonClick}>
                        <img
                            src={require('../../../../assets/images/icon-close.svg').default}
                            alt=""
                        />
                    </button>
                    <h3 className="classic-title">{title}</h3>
                </div>

                <div className="classic-body">
                    <div className="login-steps">
                        <div className="input-area">
                            <div className="label">{labelUsername}</div>
                            <div className="input">
                                <input
                                    type="text"
                                    placeholder="EX: +880 1234 567890"
                                    onChange={(e) => {
                                        loggingDetectOption(
                                            e.target.value,
                                            setUsernameType,
                                            onValidationFailed
                                        );
                                        setUsername(e.target.value);
                                    }}
                                />
                                <img
                                    className="post-icon"
                                    src={require('../../../../assets/images/icon-tick.svg').default}
                                    alt=""
                                />
                            </div>
                        </div>

                        {verificationMethod === 'password' ? (
                            <div className="input-area">
                                <div className="label">Password</div>
                                <div className="input">
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                    />
                                    <img
                                        className="post-icon"
                                        src={
                                            require('../../../../assets/images/icon-tick.svg')
                                                .default
                                        }
                                        alt=""
                                    />
                                </div>
                            </div>
                        ) : null}

                        {verificationMethod === 'otp' ? (
                            <UserOtpComp
                                otp={otp}
                                otpKey={otpKey}
                                setOtp={setOtp}
                                onResendClick={onResendClick}
                            />
                        ) : null}

                        <div className="input-area right">
                            <BtnEmailPasswordNext
                                username={username}
                                usernameType={usernameType}
                                setAccountType={setAccountType}
                                setVerificationMethod={setVerificationMethod}
                                setOtpKey={setOtpKey}
                                btnName={btnName}
                                setBtnName={setBtnName}
                            />
                            <BtnEmailPasswordLogin
                                username={username}
                                password={password}
                                usernameType={usernameType}
                                setLoginStatus={setLoginStatus}
                                setAccountType={setAccountType}
                                setVerificationMethod={setVerificationMethod}
                                btnName={btnName}
                                setBtnName={setBtnName}
                            />
                            <BtnEmailOtpLogin
                                username={username}
                                otp={otp}
                                usernameType={usernameType}
                                setLoginStatus={setLoginStatus}
                                setAccountType={setAccountType}
                                setVerificationMethod={setVerificationMethod}
                                btnName={btnName}
                                setBtnName={setBtnName}
                            />
                        </div>
                    </div>
                </div>

                <div className="classic-footer" />
            </div>
        </div>
    );
}

export default UserLoginComps;
