import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import BackdropCommon from './components/basic/BackdropCommon';
import SnackbarCommon from './components/basic/SnackbarCommon';
import AuthRouters from './routes/AuthRouters';
import CartRouters from './routes/CartRouters';
import ProductRouters from './routes/ProductRouters';
import SupportRouters from './routes/SupportRouters';
import SystemRouters from './routes/SystemRouters';
import UserRoutes from './routes/UserRouters';

function ErrorRoute() {
    return <div />;
}

export default function RouteHandler() {
    return (
        <BrowserRouter>
            <SnackbarCommon />
            <BackdropCommon />

            <Switch>
                <Route path={['/products', '/category/']}>
                    <ProductRouters />
                </Route>

                <Route path={['/', '/about', '/contact', '/support']} exact>
                    <SystemRouters />
                </Route>
                <Route path={['/support']}>
                    <SupportRouters />
                </Route>

                <Route path={['/user']}>
                    <UserRoutes />
                </Route>

                <Route
                    path={[
                        '/cart',
                        '/checkout',
                        '/invoice/:invoiceId',
                        '/invoice/:invoiceId/print',
                    ]}
                    exact
                >
                    <CartRouters />
                </Route>

                <Route path={['/login', '/registration']}>
                    <AuthRouters />
                </Route>

                <Route>
                    <ErrorRoute />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}
