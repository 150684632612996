import React from 'react';
import { useLocation } from 'react-router-dom';
import StepHandler from '../../../utils/StepHandler';
import SupportAddModal from '../modals/SupportAddModal';

export default function SupportModal({ supportDepartment }) {
    const location = useLocation();

    return (
        <StepHandler pathname={location.pathname} exiturl="/support">
            <SupportAddModal supportDepartment={supportDepartment} path="/support/add/ticket/" />
            <div path="/support/add/ticket/two" />
        </StepHandler>
    );
}
