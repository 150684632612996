/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useState } from 'react';
import { Badge, Button, Card, Container, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import NoProductImg from '../../../../assets/images/no-product.png';
import ConfigApi from '../../../../configs/ConfigApi';
import store from '../../../store';
import AxiosAuth from '../../../utils/AxiosAuth';
import FindInArray from '../../../utils/FindInArray';
import { UserAddressListModal } from '../../users/modals/UserAddressModal';
import CartProductVariant from '../comp/CartProductVariant';

function cartProductInitSet(payload) {
    return {
        type: 'CART_INIT_SET',
        payload,
    };
}
const setCartData = () =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_CART)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
const creatingInvoice = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_INVOICE_CREATE, params)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function CheckOutProduct({ myItem, qty, variation }) {
    if (myItem.id !== 0) {
        return (
            <div className="form-row" style={{ marginBottom: '10px' }}>
                <div className="col-2">
                    <div className="cart-section-product-img">
                        <img src={myItem.default_image} />
                    </div>
                </div>
                <div className="col-4" style={{ textAlign: 'left' }}>
                    <Link to={`/products/${myItem.id}/${myItem.title.replace(/[^\w\\s]/gi, '-')}`}>
                        <b>{myItem.title}</b>
                    </Link>
                    <br />
                    <ul className="variant-list">
                        <CartProductVariant
                            data={Object.values(variation).slice(
                                0,
                                Object.keys(variation).length - 2
                            )}
                        />
                    </ul>
                </div>

                <div className="col-3">
                    Qty:{qty}x{myItem.total_price.toFixed(2)}
                </div>
                <div className="col-3">
                    <p>{(qty * myItem.total_price).toFixed(2)}</p>
                </div>
            </div>
        );
    }
    return <></>;
}
function priceCalculator(checkoutData, productData) {
    let tPrice = 0;
    let tItem = 0;

    const findInArray = new FindInArray();
    findInArray.init(productData, 'id');

    checkoutData.forEach((itemAr) => {
        const id = itemAr.productId;
        itemAr.productVariation.forEach((variation) => {
            const productInfo = findInArray.find(id);
            tPrice +=
                variation.isSelected === true ? productInfo.total_price * variation.quantity : 0;

            tItem += variation.isSelected === true ? variation.quantity : 0;
        });
    });

    return { totalPrice: tPrice, totalItem: tItem };
}
function CheckoutData({ checkoutData, productData }) {
    const FindArr = new FindInArray();
    FindArr.init(productData || [], 'id');

    return (
        <div className="cart-section-product-list">
            {checkoutData?.map((cartItem) =>
                cartItem?.productVariation.map((item, index) => {
                    const itemData = FindArr.find(cartItem.productId);
                    const uKey = index;
                    if (item.isSelected === true) {
                        return (
                            <CheckOutProduct
                                myItem={itemData}
                                key={`${cartItem.productId}-${uKey}`}
                                qty={item.quantity}
                                price={item.price}
                                variation={item}
                            />
                        );
                    }
                    return null;
                })
            )}
        </div>
    );
}
function CheckOutOtherOption({
    handlePay,
    selectedType,
    address,
    totalItem,
    totalPrice,
    handleChangeType,
    setAddModal,
}) {
    return (
        <div className="col-12 col-lg-4">
            <Card>
                <Card.Body>
                    <h6> Address</h6>
                    {/* {userAddresses&& userAddresses.fil} */}
                    <div>
                        <FontAwesomeIcon icon={faMapMarkerAlt} /> {address.name}{' '}
                        <Badge variant="warning">{address.is_home === 1 ? 'Home' : 'Office'}</Badge>
                        <Badge style={{ float: 'right' }} onClick={() => setAddModal(true)}>
                            Change
                        </Badge>
                        <p>
                            {address.area},{address.district}
                        </p>
                    </div>

                    <h6>Order Summary</h6>
                    <div className="cart-section-summary">
                        <Table>
                            <tbody>
                                <tr>
                                    <td>Subtotal ({totalItem} items)</td>
                                    <td className="text-right">৳ {totalPrice.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>Shipping Fee</td>
                                    <td className="text-right">৳ 50</td>
                                </tr>
                                <tr style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                    <td>Total</td>
                                    <td className="text-right">৳ {(totalPrice + 50).toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <h6>Payment Option</h6>
                        <div className="mb-3">
                            <RadioGroup
                                aria-label="address type"
                                name="address_type"
                                value={selectedType}
                                onChange={handleChangeType}
                            >
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Cash on delivery"
                                />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Debit /Credit Card"
                                />
                                <FormControlLabel
                                    value="3"
                                    control={<Radio />}
                                    label="Mobile Banking"
                                />
                            </RadioGroup>
                        </div>
                        <Button className="btn-danger text-center w-100" onClick={handlePay}>
                            PROCEED TO PAY
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}
function CheckoutSection({ productData, shopId }) {
    const [addModal, setAddModal] = useState(false);
    const checkoutData = store.getState().cartProducts;
    const userAddresses = useSelector((state) => state.userAddresses);
    const [selectedType, setSelectedType] = useState('1');

    const handleChangeType = (event) => {
        setSelectedType(event.target.value);
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const { totalPrice, totalItem } = priceCalculator(checkoutData, productData);
    const [selectedAddress, setSelectedAddress] = React.useState(userAddresses[0].id);
    const address = userAddresses.filter(
        (add) => add.id.toString() === selectedAddress.toString()
    )[0];

    const handlePay = () => {
        if (selectedType === '1' && checkoutData.length !== 0) {
            creatingInvoice({ checkoutData, selectedAddress })
                .then((response) => {
                    // DataErrors(response, dispatch);
                    if (response.data.error === 0 && response.data.invoiceId) {
                        setCartData({ shopId })
                            .then((responseCart) => {
                                dispatch(cartProductInitSet(responseCart.data));
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                        history.push(`/invoice/${response.data.invoiceId}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    return (
        <>
            <section className="cart-section">
                <Container>
                    <div className="form-row">
                        <div className="col-12 col-lg-8 mb-4">
                            <Card className="cart-section-card">
                                <Card.Body>
                                    <div className="cart-section-product">
                                        <div className="cart-section-product-top">
                                            <h6>Checkout Products</h6>
                                        </div>
                                        {checkoutData.length !== 0 || !productData ? (
                                            <CheckoutData
                                                checkoutData={checkoutData}
                                                productData={productData}
                                            />
                                        ) : (
                                            <img
                                                src={NoProductImg}
                                                alt="No Product"
                                                width="300px"
                                                style={{ marginTop: '10px' }}
                                            />
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <CheckOutOtherOption
                            handlePay={handlePay}
                            selectedType={selectedType}
                            handleChangeType={handleChangeType}
                            totalPrice={totalPrice}
                            totalItem={totalItem}
                            address={address}
                            setAddModal={setAddModal}
                        />
                    </div>
                </Container>
            </section>
            <UserAddressListModal
                state={addModal}
                pSetAddModal={setAddModal}
                setSelectedAddress={setSelectedAddress}
                userAddresses={userAddresses}
            />
        </>
    );
}
export default CheckoutSection;
