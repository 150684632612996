import React from 'react';
import EffectOne from './effects/EffectOne';

export default function FeatureProduct(props) {
    const { data } = props;
    return (
        <div className="col-12 col-lg-4 feature-product">
            <EffectOne data={data} />
        </div>
    );
}
