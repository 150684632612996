import React from 'react';
import LoadingFullScreen from './LoadingFullScreen';

export default function Preview({ children, placeholderModel, show, errorPage }) {
    if (errorPage) {
        return errorPage;
    }

    if (show) {
        return children;
    }

    if (placeholderModel === 'app-init') {
        return <LoadingFullScreen />;
    }

    return <div>Placeholder {placeholderModel}</div>;
}
