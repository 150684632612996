import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SnackbarOpenDispatch from '../../dispatches/SnackbarOpenDispatch';

const addToCart = (productId, selectedVariation, newQuantity, shopId) => ({
    type: 'CART_ADD_PRODUCT',
    payload: {
        productId,
        selectedVariation,
        newQuantity,
        shopId,
    },
});
const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};
export default function QuantityBtn({ id, pQty, selectedVariation, shopId }) {
    const dispatch = useDispatch();

    const [newQuantity, setNewQuantity] = useState(pQty);

    const handleQtyPlus = () => {
        setNewQuantity(newQuantity + 1);
        dispatch(addToCart(id, selectedVariation, newQuantity + 1, shopId));
        handelSnackbarOpen(dispatch, 'Cart Qty Plus');
    };
    const handleQtyMinus = () => {
        if (newQuantity <= 1) {
            setNewQuantity(1);
        } else {
            setNewQuantity(newQuantity - 1);
            dispatch(addToCart(id, selectedVariation, newQuantity - 1, shopId));
            handelSnackbarOpen(dispatch, 'Cart Qty Minus');
        }
    };

    const handleChange = (e) => {
        setNewQuantity(e.target.value);
        dispatch(addToCart(id, selectedVariation, e.target.value, shopId));
        handelSnackbarOpen(dispatch, 'Cart Qty Change');
    };

    return (
        <>
            <FontAwesomeIcon onClick={handleQtyMinus} size="sm" icon={faMinus} />
            <input
                style={{ border: 'none', width: '50px', textAlign: 'center' }}
                onChange={handleChange}
                value={newQuantity}
            />
            <FontAwesomeIcon onClick={handleQtyPlus} size="sm" icon={faPlus} />
        </>
    );
}
