/* eslint-disable react/jsx-props-no-spreading */
import { TextField, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import SnackbarOpenDispatch from '../../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { UserModalFooter, UserModalHeader } from './comp/UserModalHeaderFooter';

function userInfoInitSet(payload) {
    return {
        type: 'USER_INIT_SET',
        payload,
    };
}
const updateUserPassword = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_USER_UPDATE_PASSWORD, params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};
const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};
export default function UserPasswordUpdateModal(props) {
    const { prev, next, step, paths, exiturl } = props;

    const theme = useTheme();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const { shopId, userInfo } = InitInfo();
    const [oldPassValue, setOldPassValue] = useState('');
    const [newPassValue, setNewPassValue] = useState('');
    const [reNewPassValue, setReNewPassValue] = useState('');
    const dispatch = useDispatch();

    const history = useHistory();

    const handleOldPassValue = (event) => {
        setOldPassValue(event.target.value);
    };

    const handleNewPassValue = (event) => {
        setNewPassValue(event.target.value);
    };
    const handleReNewPassValue = (event) => {
        setReNewPassValue(event.target.value);
    };

    const handleNextClick = () => {
        // console.dir(next);
        updateUserPassword(
            {
                oldPass: oldPassValue,
                newPass: newPassValue,
                reNewPass: reNewPassValue,
                id: userInfo.id,
                shopId,
            },
            (response) => {
                if (response.data.error === 0) {
                    // --Update Store
                    dispatch(userInfoInitSet(response.data.userInfo));
                    handelSnackbarOpen(dispatch, response.data.message);
                    history.push(exiturl);
                }
            }
        );
    };

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <UserModalHeader prev={prev} step={step} exiturl={exiturl} />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Update Password
                        </Typography>
                        <TextField
                            required
                            label="Old Password"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter old password"
                            onChange={handleOldPassValue}
                            type="password"
                        />
                        <TextField
                            required
                            label="New Password"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter new password"
                            onChange={handleNewPassValue}
                            type="password"
                        />
                        <TextField
                            required
                            label="Re-type New Password"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter re-type new password"
                            onChange={handleReNewPassValue}
                            type="password"
                        />
                    </div>
                    <UserModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={reNewPassValue}
                        handleNextClick={handleNextClick}
                        skip
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
