import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import SnackbarOpenDispatch from '../../dispatches/SnackbarOpenDispatch';

const addToCart = (productId, selectedVariation, newQuantity) => ({
    type: 'CART_ADD_PRODUCT',
    payload: {
        productId,
        selectedVariation,
        newQuantity,
    },
});
const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};
function ThemeButton({
    variant,
    myClassName,
    icon,
    title,
    size,
    id,
    selectedVariation,
    isDisable = true,
    handleModalClose,
    newQuantity,
    successMessage,
}) {
    const dispatch = useDispatch();
    const handelBuyNow = () => {
        dispatch(addToCart(id, selectedVariation, newQuantity));
        handelSnackbarOpen(dispatch, successMessage);
        if (handleModalClose) {
            handleModalClose();
        }
    };

    return (
        <Button
            variant={variant || 'outline-info'}
            className={[myClassName || 'ThemeBtn'].join(' ')}
            size={size || 'sm'}
            onClick={handelBuyNow}
            disabled={isDisable}
        >
            {icon && <FontAwesomeIcon icon={icon} />}
            {icon ? ' ' : ''} {title || 'Button'}
        </Button>
    );
}

export default ThemeButton;
