import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import CartSection from '../section/CartSection';

function ProductCartPage() {
    PageSetProperties('Cart');
    const dispatch = useDispatch();
    const { shopData, initData, products } = InitInfo();
    const history = useHistory();

    const [show, setShow] = useState(!!initData.initId);
    const isLogin = localStorage.getItem('Secure-Access');

    useEffect(() => {
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                if (isLogin) {
                    setShow(initStatus);
                } else {
                    history.push('/registration?cart');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, history, isLogin]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu shopData={shopData} />
            <CartSection productData={products} shopId={shopData?.shopInfo?.sl} />
            <Footer />
        </Preview>
    );
}
export default ProductCartPage;
