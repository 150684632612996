/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-const-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import DeliveredIcon from '../../../../assets/images/deliveredIcon.svg';
import DeliveredIconRed from '../../../../assets/images/deliveredIconRed.svg';
import PlacedIcon from '../../../../assets/images/placedIcon.svg';
import ProcessingIcon from '../../../../assets/images/processingIcon.svg';
import ProcessingIconRed from '../../../../assets/images/processingIconRed.svg';
import ShippedIcon from '../../../../assets/images/shippedIcon.svg';
import ShippedIconRed from '../../../../assets/images/shippedIconRed.svg';
import ConfigApi from '../../../../configs/ConfigApi';
import Rating from '../../../components/basic/Rating';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import Footer from '../../system/section/FooterSection';
import HeaderSection from '../../system/section/HeaderSection';
import UserMenu from '../../users/comp/UserMenu';

const getInvoiceData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_INVOICE, {
            params,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function OrderDetailRelatedProduct({ products }) {
    return (
        <div className="order-section-detail-related-product">
            <div className="order-section-detail-related-title">You May Also See</div>
            <div className="row">
                {products.slice(0, 6).map((row) => (
                    <div className="col-4">
                        <div className="order-section-detail-related-product-list">
                            <div className="">
                                <img
                                    className="product-section-imgBox"
                                    src={`https://www.optimizee.xyz/images/${btoa(
                                        row.default_image
                                    )},200,300.jpg`}
                                    alt={row.title}
                                />
                            </div>
                            <div className="order-section-detail-related-product-list-title">
                                {row.title}
                            </div>
                            <div className="order-section-detail-related-product-price">
                                <strong>৳ {row.total_price}</strong>
                                <span className="offer" />
                                <div className="product-section-rating">
                                    <Rating />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
function OrderDetailProducts({ products, invoiceItemData }) {
    const FindArr = new FindInArray();
    FindArr.init(products, 'id');

    return (
        <div className="order-section-detail-products">
            <div className="row">
                <div className="col-12">
                    {invoiceItemData &&
                        invoiceItemData.map((item) => {
                            const itemData = FindArr.find(item.productId);

                            return (
                                <div className="row bottom-border" key={item.id}>
                                    <div className="col-4 col-lg-2">
                                        <div className="user-orders-img">
                                            <img
                                                src={`https://www.optimizee.xyz/images/${btoa(
                                                    itemData.default_image
                                                )},200,300.jpg`}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-8 col-lg-5">
                                        <div className="order-section-detail-title">
                                            <Link to={item.url}>
                                                <b>{itemData.title}</b>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-2 order-viewQty">
                                        {item.price * item.quantity}
                                    </div>
                                    <div className="col-1 order-viewQty">x{item.quantity}</div>
                                    <div className="col-2 order-viewStatus">
                                        <button
                                            type="button"
                                            className="order-section-detail-cancle-btn"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
function OrderDetailTimeline({ timeLineAr }) {
    return (
        <div className="order-section-timeline">
            <div className="row">
                <div className="col-3 order-section-timeline-p-m-0">
                    <div className="order-section-timeline-item">
                        <div className="order-section-timeline-line-blank-fill" />
                        <div className="order-section-timeline-circle-fill">
                            <img src={PlacedIcon} alt="" />
                        </div>
                        <div className="order-section-timeline-line-fill" />
                    </div>
                    <div className="order-section-timeline-title">Placed</div>
                </div>
                <div className="col-3 order-section-timeline-p-m-0">
                    {!timeLineAr.processing ? (
                        <div className="order-section-timeline-line-joint">
                            <div className="order-section-timeline-line-joint-in" />
                        </div>
                    ) : (
                        ''
                    )}
                    {timeLineAr.processing ? (
                        <div className="order-section-timeline-item">
                            <div className="order-section-timeline-line-fill" />
                            <div className="order-section-timeline-circle-fill">
                                <img src={ProcessingIcon} alt="" />
                            </div>
                            <div className="order-section-timeline-line-fill" />
                        </div>
                    ) : (
                        <div className="order-section-timeline-item">
                            <div className="order-section-timeline-line-not-fill" />
                            <div className="order-section-timeline-circle-not-fill">
                                <img src={ProcessingIconRed} alt="Processing Red" />
                            </div>
                            <div className="order-section-timeline-line-not-fill" />
                        </div>
                    )}
                    <div className="order-section-timeline-title">Processing</div>
                </div>
                <div className="col-3 order-section-timeline-p-m-0">
                    {!timeLineAr.shipped && timeLineAr.processing ? (
                        <div className="order-section-timeline-line-joint">
                            <div className="order-section-timeline-line-joint-in" />
                        </div>
                    ) : (
                        ''
                    )}
                    {timeLineAr.shipped ? (
                        <div className="order-section-timeline-item">
                            <div className="order-section-timeline-line-fill" />
                            <div className="order-section-timeline-circle-fill">
                                <img src={ShippedIcon} alt="" />
                            </div>
                            <div className="order-section-timeline-line-fill" />
                        </div>
                    ) : (
                        <div className="order-section-timeline-item">
                            <div className="order-section-timeline-line-not-fill" />
                            <div className="order-section-timeline-circle-not-fill">
                                <img src={ShippedIconRed} alt="" />
                            </div>
                            <div className="order-section-timeline-line-not-fill" />
                        </div>
                    )}
                    <div className="order-section-timeline-title">Shipped</div>
                </div>
                <div className="col-3 order-section-timeline-p-m-0">
                    {!timeLineAr.delivered && timeLineAr.shipped ? (
                        <div className="order-section-timeline-line-joint">
                            <div className="order-section-timeline-line-joint-in" />
                        </div>
                    ) : (
                        ''
                    )}
                    {timeLineAr.delivered ? (
                        <div className="order-section-timeline-item">
                            <div className="order-section-timeline-line-fill" />
                            <div className="order-section-timeline-circle-fill">
                                <img src={DeliveredIcon} alt="" />
                            </div>
                        </div>
                    ) : (
                        <div className="order-section-timeline-item">
                            <div className="order-section-timeline-line-not-fill" />
                            <div className="order-section-timeline-circle-not-fill">
                                <img src={DeliveredIconRed} alt="" />
                            </div>
                        </div>
                    )}
                    <div className="order-section-timeline-title">Delivered</div>
                </div>
            </div>
        </div>
    );
}
function OrderDetailTracking({ timeLineDetAr }) {
    let sho = 0;
    return (
        <div className="order-section-detail-tracking">
            <div className="order-section-detail-tracking-title">
                Tracking: <b>#PPR10089784475</b>
            </div>
            <hr />
            <div className="order-section-detail-tracking-history">
                <ul>
                    {timeLineDetAr?.map((tRow) => {
                        sho += 1;
                        return (
                            <li>
                                <b className={sho === 1 ? 'history-title' : 'history-title2'}>
                                    Your product has been {tRow.status}
                                </b>
                                <br />
                                <p>{tRow.held_time}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}
function OrderDetailInfo({ invoiceData, invoiceItemData, addressAr }) {
    let totalAmount = 0;

    return (
        <div className="order-section-detail-info">
            <div className="order-section-detail-info-title">
                <div className="row">
                    <div
                        className="col-6"
                        style={{
                            borderBottom: '1px solid #e5dede',
                            marginBottom: '10px',
                        }}
                    >
                        <h5 className="user-order-section-number">
                            Order Id: #
                            <Link to={`/user/order-detail/${invoiceData.id}`}>
                                INV {invoiceData.id}
                            </Link>
                        </h5>
                        <h6 className="user-order-section-time">
                            Placed on {invoiceData.time_created}
                        </h6>
                    </div>
                    <div
                        className="col-6"
                        style={{
                            borderBottom: '1px solid #e5dede',
                            marginBottom: '10px',
                        }}
                    >
                        <button
                            type="button"
                            className="ml-3 float-right user-order-section-btn-detail"
                            style={{ width: 150 }}
                        >
                            Contact Support
                        </button>
                    </div>
                </div>
            </div>
            <div className="order-section-detail-info-address">
                <div className="row">
                    <div className="col-4 order-section-detail-info-title">Shipping Address</div>
                    <div className="col-3 order-section-detail-info-data">{addressAr.name}</div>
                    <div className="col-5 order-section-detail-info-data">{addressAr.area}</div>
                </div>
            </div>
            <div className="order-section-detail-info-payment">
                <div className="row">
                    <div className="col-4 order-section-detail-info-title">Paid With</div>
                    <div className="col-3 order-section-detail-info-data">bKash</div>
                    <div className="col-5 order-section-detail-info-data">+880 1234 5678 90</div>
                </div>
            </div>
            <div className="order-section-detail-info-payment-history">
                {invoiceItemData &&
                    invoiceItemData.map((item) => {
                        totalAmount += item.price * item.quantity;
                        return totalAmount;
                    })}
                <table>
                    <tbody>
                        <tr>
                            <td className="order-section-detail-info-title">Subtotal</td>
                            <td className="order-section-detail-info-data">{totalAmount}</td>
                        </tr>
                        <tr>
                            <td className="order-section-detail-info-title">Shipping Fee</td>
                            <td className="order-section-detail-info-data">75.00</td>
                        </tr>
                        <tr>
                            <td className="order-section-detail-info-title">Platform Promotion</td>
                            <td className="order-section-detail-info-data">0.00</td>
                        </tr>
                        <tr>
                            <td className="order-section-detail-info-title">Order Total</td>
                            <td className="order-section-detail-info-data">{totalAmount + 75}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
function OrderDetailsPage() {
    PageSetProperties('Order Detail');
    const { initData, products, shopData } = InitInfo();

    const dispatch = useDispatch();
    const [show, setShow] = useState(!!initData.initId);
    const [invoiceItemData, setInvoiceItemData] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const history = useHistory();
    const { invoiceId } = useParams();
    const isLogin = localStorage.getItem('Secure-Access');
    const [addressAr, setAddressAr] = useState([]);
    const [timeLineAr, setTimeLineAr] = useState([]);
    const [timeLineDetAr, setTimeLineDetAr] = useState([]);

    React.useEffect(() => {
        // Collect API Data
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                if (isLogin) {
                    getInvoiceData({ invoiceId })
                        .then((response) => {
                            setInvoiceData(response.data.invoice);
                            setInvoiceItemData(response.data.invoiceItem);
                            setAddressAr(response.data.addressAr);
                            setTimeLineAr(response.data.invoiceTimeLine);
                            setTimeLineDetAr(response.data.invoiceTimeLineDetailAr);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    setShow(initStatus);
                } else {
                    history.push('/registration');
                }
            })
            .catch((err) => {
                console.log(err);
            });
        // Collect invoice data
    }, [dispatch, history, invoiceId, isLogin]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu />

            <section className="user-section">
                <Container>
                    <div className="row">
                        <UserMenu />
                        <div className="col-12 col-lg-9">
                            <div className="order-section-detail">
                                <OrderDetailTimeline timeLineAr={timeLineAr} />
                                <OrderDetailTracking timeLineDetAr={timeLineDetAr} />

                                <OrderDetailProducts
                                    invoiceItemData={invoiceItemData}
                                    products={products}
                                />
                                <OrderDetailInfo
                                    invoiceData={invoiceData}
                                    invoiceItemData={invoiceItemData}
                                    addressAr={addressAr}
                                />

                                <OrderDetailRelatedProduct products={products} />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer />
        </Preview>
    );
}

export default OrderDetailsPage;
