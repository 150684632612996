import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card, Container, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NoProductImg from '../../../../assets/images/no-product.png';
import QuantityBtn from '../../../components/basic/QuantityBtn';
import SnackbarOpenDispatch from '../../../dispatches/SnackbarOpenDispatch';
import store from '../../../store';
import FindInArray from '../../../utils/FindInArray';
import CartProductVariant from '../comp/CartProductVariant';

const addToSelect = (productId, selectedVariation, isSelected, shopId) => ({
    type: 'CART_PRODUCT_SELECT',
    payload: {
        productId,
        selectedVariation,
        isSelected,
        shopId,
    },
});

const updateProductItem = (shopId, productId, selectedVariation, newQuantity = -1) => ({
    type: 'CART_ADD_PRODUCT',
    payload: {
        productId,
        selectedVariation,
        newQuantity,
        shopId,
    },
});
const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};

function CartProduct({ myItem: item, quantity, variation, shopId }) {
    const dispatch = useDispatch();
    // console.log(variation);
    const handleRemove = () => {
        dispatch(updateProductItem(shopId, item.id, variation));
        handelSnackbarOpen(dispatch, 'Cart Remove');
    };

    const handleProductSelect = (e) => {
        dispatch(addToSelect(item.id, variation, e.target.checked, shopId));
    };

    if (item.id !== 0 && variation.quantity !== 0) {
        return (
            <div className="form-row" style={{ marginBottom: '10px' }}>
                <div className="col-1">
                    <input
                        type="checkbox"
                        onChange={handleProductSelect}
                        checked={variation.isSelected}
                    />
                </div>
                <div className="col-2">
                    <div className="cart-section-product-img">
                        <img src={item.default_image} alt="" />
                    </div>
                </div>
                <div className="col-4" style={{ textAlign: 'left' }}>
                    <Link to={`/products/${item.id}/${item.title.replace(/[^\w\\s]/gi, '-')}`}>
                        <b>{item.title}</b>
                    </Link>
                    <br />
                    <ul className="variant-list">
                        <CartProductVariant
                            data={Object.values(variation).slice(
                                0,
                                Object.keys(variation).length - 2
                            )}
                        />
                    </ul>
                </div>
                <div className="col-1">
                    <small>{item.total_price.toFixed(2)}</small>
                </div>
                <div className="col-2">
                    <QuantityBtn
                        id={item.id}
                        pQty={variation.quantity}
                        selectedVariation={variation}
                        shopId={shopId}
                    />
                </div>
                <div className="col-2">
                    {variation.isSelected ? (
                        variation.quantity * item.total_price
                    ) : (
                        <FontAwesomeIcon
                            icon={faTrash}
                            color="red"
                            value={quantity}
                            onClick={handleRemove}
                        />
                    )}
                </div>
            </div>
        );
    }
    return null;
}

function CartData({ cartData, productData, shopId }) {
    const FindArr = new FindInArray();
    FindArr.init(productData, 'id');

    return (
        <div className="cart-section-product-list">
            {cartData.map((cartItem) =>
                cartItem.productVariation.map((item, index) => {
                    const itemData = FindArr.find(cartItem.productId);
                    const uKey = index;
                    if (itemData) {
                        return (
                            <CartProduct
                                myItem={itemData}
                                key={`${cartItem.productId}-${uKey}`}
                                quantity={cartItem.quantity}
                                variation={item}
                                shopId={shopId}
                            />
                        );
                    }
                    return null;
                })
            )}
        </div>
    );
}

function priceCalculator(cartProducts, productData) {
    let tPrice = 0;
    let tItem = 0;

    const findInArray = new FindInArray();
    findInArray.init(productData, 'id');

    cartProducts?.forEach((itemAr) => {
        const id = itemAr.productId;
        itemAr.productVariation.forEach((variation) => {
            const productInfo = findInArray.find(id);
            tPrice +=
                variation.isSelected === true ? productInfo.total_price * variation.quantity : 0;

            tItem += variation.isSelected === true ? variation.quantity : 0;
        });
    });

    return { totalPrice: tPrice, totalItem: tItem };
}
function CartSummery({ totalItem, totalPrice, cartData }) {
    return (
        <div className="col-12 col-lg-4">
            <Card>
                <Card.Body>
                    <h6>Order Summary</h6>
                    <div className="cart-section-summary">
                        <Table>
                            <tbody>
                                <tr>
                                    <td>Subtotal ({totalItem} items)</td>
                                    <td className="text-right">৳ {totalPrice}</td>
                                </tr>

                                <tr style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                    <td>Total</td>
                                    <td className="text-right">৳ {totalPrice}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Button
                            style={{
                                pointerEvents: cartData.length <= 0 ? 'none' : '',
                            }}
                            className={['btn-danger text-center w-100'].join()}
                        >
                            <Link to="/checkout" style={{ color: 'white' }}>
                                PROCEED TO CHECKOUT{' '}
                            </Link>
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}
function CartSection({ productData, shopId }) {
    const { totalPrice, totalItem } = useSelector((state) => {
        const { cartProducts } = state;
        return priceCalculator(cartProducts, productData);
    });

    const cartData = store.getState().cartProducts;

    return (
        <section className="cart-section">
            <Container>
                <div className="form-row">
                    <div className="col-12 col-lg-8 mb-4">
                        <Card className="cart-section-card">
                            <Card.Body>
                                <div className="cart-section-product">
                                    <div className="cart-section-product-top">
                                        <h6>Cart Products</h6>
                                    </div>
                                    {cartData.length !== 0 ? (
                                        <CartData
                                            productData={productData}
                                            cartData={cartData}
                                            shopId={shopId}
                                        />
                                    ) : (
                                        <img
                                            src={NoProductImg}
                                            alt="No Product"
                                            width="300px"
                                            style={{ marginTop: '10px' }}
                                        />
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <CartSummery
                        totalPrice={totalPrice}
                        totalItem={totalItem}
                        cartData={cartData}
                    />
                </div>
            </Container>
        </section>
    );
}
export default CartSection;
