import { faBars, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Container, FormControl, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import rupkathaLogo from '../../../../assets/images/rupkatha-logo2.svg';
import store from '../../../store';
// import MenuData from '../../../configs/MenuData';
import CartOption from '../comp/CartOption';
import MenuPrimary from '../comp/MenuPrimary';
import SearchOption from '../comp/SearchOption';
import TopHeader from '../comp/TopHeader';
import UserOption from '../comp/UserOption';

function classNameControl(top, whiteMenu) {
    const newClassList = ['menu-section'];

    if (top > 10) {
        newClassList.push();

        newClassList.push('white');
        newClassList.push('position-higher');
    } else {
        newClassList.push('position-lower');
    }

    if (whiteMenu) {
        if (newClassList.indexOf('white') === -1) {
            newClassList.push('white');
        }
    }
    return newClassList;
}

function HeaderSection({ whiteMenu, shopData }) {
    const headerRef = React.useRef(null);
    const { categories } = store.getState().shopData;
    const [searchOpen, setSearchOpen] = useState('none');
    const [otherHide, setOtherHide] = useState('');

    // const MenuData = MenuDataCreator(categories);

    const handleSearchOpen = () => {
        setSearchOpen('block');
        setOtherHide('none');
    };

    const handleCloseSearchOption = () => {
        setSearchOpen('none');
        setOtherHide('');
    };

    useEffect(() => {
        if (headerRef?.current) {
            headerRef.current.className = classNameControl(0, whiteMenu).join(' ');

            const onScroll = (e) => {
                const topPosition = e.target.documentElement.scrollTop;
                headerRef.current.className = classNameControl(topPosition, whiteMenu).join(' ');
            };
            window.addEventListener('scroll', onScroll);

            return () => window.removeEventListener('scroll', onScroll);
        }
        return null;
    }, [whiteMenu]);

    return (
        <section className="menu-section" ref={headerRef}>
            <div className="top-header">
                <Container>
                    <TopHeader shopData={shopData} />
                </Container>
            </div>

            <div className="menu-header">
                <Container>
                    <div className="row">
                        <div className="col-12" style={{ display: `${searchOpen}` }}>
                            <InputGroup className="mb-2 mt-2">
                                <FormControl
                                    id="inlineFormInputGroup"
                                    placeholder="Search Here"
                                    style={{
                                        backgroundColor: '#ffffff',
                                        borderRight: 'none',
                                    }}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            backgroundColor: '#ffffff',
                                            borderLeft: 'none',
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            onClick={handleCloseSearchOption}
                                            icon={faTimes}
                                        />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </div>
                        <div className="col-2 m-menu-bar" style={{ display: `${otherHide}` }}>
                            <FontAwesomeIcon icon={faBars} />
                        </div>
                        <div className="col-8 col-lg-5" style={{ display: `${otherHide}` }}>
                            <div className="menu-header-left">
                                <Link to="/">
                                    <img className="brand-logo" src={rupkathaLogo} alt="" />
                                </Link>
                                <div className="responsive-menu-view">
                                    <MenuPrimary MenuData={categories} />
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-2 col-lg-7 text-right"
                            style={{ display: `${otherHide}` }}
                        >
                            <div className="m-search-bar text-center">
                                <FontAwesomeIcon onClick={handleSearchOpen} icon={faSearch} />
                            </div>
                            <div className="responsive-option-view">
                                <UserOption />
                                <CartOption />
                                <SearchOption />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default HeaderSection;
