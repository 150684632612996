import React, { useEffect, useState } from 'react';

function updateTime(seconds, setSeconds, handleChange) {
    setSeconds(seconds - 1);

    if (typeof handleChange === 'function') {
        handleChange(seconds - 1);
    }
}

export default function TimerCount({ second, handleChange }) {
    const [seconds, setSeconds] = useState(second);

    useEffect(() => {
        if (seconds > 0) {
            const token = setTimeout(() => {
                updateTime(seconds, setSeconds, handleChange);
            }, 1000);

            return () => {
                clearTimeout(token);
            };
        }

        return null;
    }, [handleChange, seconds]);

    return <span>{seconds}</span>;
}
