import { faSortDown, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function Rating(props) {
    const handleRatingDetail = () => {};
    const { color, detail } = props;

    return (
        <>
            <FontAwesomeIcon icon={faStar} color={color || 'gray'} />
            <FontAwesomeIcon icon={faStar} color={color || 'gray'} />
            <FontAwesomeIcon icon={faStar} color={color || 'gray'} />
            <FontAwesomeIcon icon={faStar} color={color || 'gray'} />
            <FontAwesomeIcon icon={faStar} color={color || 'gray'} />
            {detail === true && (
                <FontAwesomeIcon icon={faSortDown} onMouseOver={handleRatingDetail} />
            )}
        </>
    );
}
export default Rating;
