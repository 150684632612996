import React from 'react';
import { Card } from 'react-bootstrap';
import ProductData from '../../../../configs/ProductData';
import UserOrders from './UserOrders';

function UsersDashboard() {
    return (
        <>
            <div className="col-12 col-lg-9 ">
                {ProductData.map((item) => (
                    <Card className="user-card" key={item.id}>
                        <Card.Body>
                            <UserOrders itemData={item} />
                        </Card.Body>
                    </Card>
                ))}
            </div>
        </>
    );
}
export default UsersDashboard;
