/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import SnackbarOpenDispatch from '../../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import HeaderSection from '../../system/section/HeaderSection';
import SupportCompleteModal from '../modals/SupportCompleteModal';
import SupportDetailSection from '../section/SupportDetailSection';

const getSupportData = () =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_SUPPORT)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
const getSupportTicketData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(
            ConfigApi.API_SUPPORT_TICKET_MESSAGE.replace(':shopId', params.shopId).replace(
                ':ticketId',
                params.ticketId
            ),
            params
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const creatingTicketMessage = (dispatch, params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_SUPPORT_TICKET_MESSAGE_CREATE, params)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const completingTicket = (dispatch, params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_SUPPORT_TICKET_COMPLETE.replace(':shopId', params.shopId).replace(
                ':ticketId',
                params.ticketId
            ),
            params
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};
export default function SupportDetailPage() {
    PageSetProperties('Support Detail');
    const dispatch = useDispatch();
    const history = useHistory();

    const { ticketId } = useParams();
    const { shopId, shopData, initData } = InitInfo();
    const [show, setShow] = useState(!!initData.initId);

    const [supportData, setSupportData] = useState([]);
    const [supportTicketMessageData, setSupportTicketMessageData] = useState([]);
    const [message, setMessage] = useState('');
    const [messageFile, setMessageFile] = useState('');
    const [completeModalShow, setCompleteModalShow] = useState(false);
    const [isSolved, setIsSolved] = useState(1);
    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(0);

    const handleReviewValueChange = (event) => {
        if (event.target.name === 'isSolved') {
            setIsSolved(event.target.value);
        }
        if (event.target.name === 'comment') {
            setComment(event.target.value);
        }
        if (event.target.name === 'rating') {
            setRating(event.target.value);
        }
    };

    const handleMessage = (event) => {
        setMessage(event.target.value);
    };
    const handleRemoveFile = () => {
        setMessageFile('');
        setMessage('');
    };
    useEffect(() => {
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
                setMessageFile('');
                setMessage('');
                getSupportData()
                    .then((response) => {
                        setSupportData(response.data.supportAr);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                getSupportTicketData({ shopId, ticketId })
                    .then((response) => {
                        setSupportTicketMessageData(response.data.ticketMessages);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, shopId, ticketId]);

    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('image', files[x]);
            await AxiosAuth.post(ConfigApi.API_IMAGE_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    setMessageFile(response.data.imageUrl);
                    setMessage(response.data.imageName);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const sendMessage = (e) => {
        e.preventDefault();
        creatingTicketMessage(dispatch, {
            shopId,
            ticketId,
            message,
            messageFile,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setSupportTicketMessageData(response.data.ticketMessages);
                    setMessage('');
                    setMessageFile('');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const completeTicket = (e) => {
        e.preventDefault();
        completingTicket(dispatch, {
            shopId,
            ticketId,
            isSolved,
            comment,
            rating,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handelSnackbarOpen(dispatch, response.data.message);

                    history.push(`/support/`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Preview placeholderModel="app-init" show={show}>
            <HeaderSection whiteMenu shopData={shopData} />
            <SupportDetailSection
                supportData={supportData}
                supportTicketMessageData={supportTicketMessageData}
                handleMessage={handleMessage}
                message={message}
                sendMessage={sendMessage}
                handleOnFileChange={handleOnFileChange}
                messageFile={messageFile}
                handleRemoveFile={handleRemoveFile}
                completeTicket={completeTicket}
                setCompleteModalShow={setCompleteModalShow}
            />
            <SupportCompleteModal
                modalShow={completeModalShow}
                setCompleteModalShow={setCompleteModalShow}
                completeTicket={completeTicket}
                isSolved={isSolved}
                comment={comment}
                rating={rating}
                handleReviewValueChange={handleReviewValueChange}
            />
        </Preview>
    );
}
