import React from 'react';
import { useLocation } from 'react-router-dom';
import StepHandler from '../../../utils/StepHandler';
import UserContactUpdateModal from './UserContactUpdateModal';
import UserEmailUpdateModal from './UserEmailUpdateModal';
import UserNameUpdateModal from './UserNameUpdateModal';
import UserPasswordUpdateModal from './UserPasswordUpdateModal';

export default function UserUpdateModals() {
    const location = useLocation();
    return (
        <StepHandler pathname={location.pathname} exiturl="/user/profile/">
            <UserNameUpdateModal path="/user/profile/update/name/" />
            <UserEmailUpdateModal path="/user/profile/update/email/" />
            <UserContactUpdateModal path="/user/profile/update/contact/" />
            <UserPasswordUpdateModal path="/user/profile/update/password/" />
        </StepHandler>
    );
}
